.background {
  width: 100%; /* Making it occupy the full screen */
  height: 100%; /* Making it occupy the full screen */

  position: fixed; /* Putting it on the top left corner */
  left: 0; /* Putting it on the top left corner */
  top: 0; /* Putting it on the top left corner */
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.75);
  transition: all .3s ease;
}

.popup-wrapper {
  width: 42vw;
  max-height: calc(60vh + 7vw);

  padding-bottom: 0.69vw;
  padding-top: 0.69vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: white;
  border-radius: 1.74vw;
  transition: all .3s ease;

}

.popup-header {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2.08vw;
  margin-right: 2.08vw;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  color: #02112E;
}

.popup-header span {
  cursor: pointer;
  transform: rotate(45deg); /* Since we used the '+' character we rotated it to look like a close button */

  font-size: 3.47vw;
}

.popup-body {
  max-height: calc(60vh + 3vw);

  margin-bottom: 1vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-body-main {
  max-height: 60vh;

  overflow-y: scroll;
}