.forgot-password-veri-background{
    width: 100%;
    height: 100vh;
    min-height: 500px;

    display: flex;

    background-color: #252F46;
}

.forgot-password-veri-main {
    height: 90%;
    width: 92%;
    
    margin: auto; /* Used to center the object */

    display: flex;

    position: relative; /* Used since we are going to have a position: absolute on a child */

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1.4vw 0.14vw rgba(0, 0, 0, 0.5);
}

.forgot-password-veri-container {
    width: 70%;
    height: 100%;
    box-sizing: border-box; /* So it includes the padding */

    margin: auto;

    padding-top: 17%;
    padding-bottom: 17%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;  
} 

.forgot-password-veri-container .back{
    width: 1.74vw;
    height: 1.74vw;

    position: absolute; /* We want this fixed to main at a distance of the top of 1.4vw and off the left of 1.4vw */
    top: 1.4vw;
    left: 1.4vw;

    color: #02112E;
    font-size: 1.52vw;
}


.forgot-password-veri-container h1 {
    font-size: 2.29vw;
}

#forgot-password-veri-secondary-text{
    width: 70%;

    margin: auto; /* Used to center the object */
    
    font-size: 1.25vw;
}

#forgot-password-veri-button-wrapper {
    margin-top: 1.73vw;
}

/* Adaptations to mobile interface */
@media screen and (max-width: 650px) { 

    .forgot-password-veri-background {
        height: stretch; /* Ths is used so that the view occupies all available space */
        min-height: 140vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */
    }

    .forgot-password-veri-main {
        height: calc(195px + 54vh); /* Since percentage measures weren't working we used this calculation that is a 
                                    combination between a fixed measure ( px ) and a measure that varies with the height 
                                    of the viewport ( vh ). */
        min-height: 130vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */
    }

    .forgot-password-veri-container {
        width: 85%;
        height: 55%;

        padding-top: 0;
        padding-bottom: 0;

        margin-top: auto; /* Used to center the object */
        margin-bottom: auto; /* Used to center the object */
    } 

    .forgot-password-veri-container .back{
        top: 6vw;
        left: 6vw;

        color:#02112E;
        font-size: 4vw; 

    }

    .forgot-password-veri-container h1 {
        margin-top: 0;

        font-size: 6vw;
    }
    
    #forgot-password-veri-secondary-text{
        width: 100%;

        font-size: 3.6vw;
    }

    #forgot-password-veri-button-wrapper {
        margin-top: 8vw;
    }

}