.footer {
      width: 85%;
      
      margin-top: 0;
      margin-bottom: 0; /* Defined has 0 since the margin will be defined in other component */
      margin-left: auto; /* Used to center element */
      margin-right: auto; /* Used to center element */

 }

.div-white-line {
      width: 100%;
      height: 0.1vw;

      margin-bottom: 1.4vw;

      background-color: white;
      border-radius: 0.05vw;
}

.div-footer-main {
      padding-left: 2.78vw; /* Used to push the image inwards relative to the border */
      padding-right: 2.78vw; /* Used to push the image inwards relative to the border */
      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
}

.footer-category {
      height: 12.5vw;
      width: 15vw;
      box-sizing: border-box; /* Used to include the padding */
      
      padding: 1.39vw;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
}

.footer-category img {
      width: 100%; /* Used to force the logo to respect the aspect ratio */
}

.footer-category h3{
      margin: 0;
      margin-bottom: 0.35vw; /* Used to give extra margin between the title and the list items */
      
      color: white;
      font-size: 1.53vw;
      font-weight: 600;
}
  
.footer-category a{
      color: white;
      font-size: 0.97vw;
      font-weight: 400;
      text-decoration: none;
      
      transition: color 0.4s;
}
  
.footer-category a:hover {
      color: #BBBBBB;
}

#logo-and-social {
      justify-content: space-around; /* This footer-category is already display flex but needed a different justify-content */
}

.div-social{
      width: 70%;
      
      margin-left: auto; /* Used to center element */
      margin-right: auto; /* Used to center element */
      
      display: flex;
      justify-content: space-around;
      flex-direction: row;
}
  
.div-social .social-icon{
      color: white;
      font-size: 1.25vw;
      
      transition: color 0.3s;
}

.div-social .social-icon:hover{
      color: #BBBBBB;
}

.footer-category .language-button {
      margin-top: -0.347vw;

      background-color: transparent;
      border: none;
      cursor:pointer;
      
      color: white;
      font-size: 1.11vw;
      font-weight: 600;
      text-decoration: none;
}

.text-copyright {
      margin-top: 1vw;
      margin-bottom: 1.39vw;
      
      color: white;
      font-size: 0.97vw;
}

/* Adaptations to mobile interface */

@media screen and (max-width: 650px) { 

      .footer {
            width: 90%; /* Increased to fit better in mobile */
      }
      
      .div-footer-main {
            flex-direction: column;
      }

      .footer-category .language-button {
            margin-top: -2vw;
      
            font-size: 3.5vw;
      }

      .footer-category {
            height: 40vw;
            width: 51.28vw;
      
            margin-left: auto; /* Used to center element */
            margin-right: auto; /* Used to center element */
      }

      .footer-category img {
            width: 80%; /* Used to force the logo to respect the aspect ratio */

            margin-left: auto;
            margin-right: auto;
      }

      .footer-category h3{
            margin-top: 3.5vw;
            font-size: 4.5vw;
      }
        
      .footer-category a{
            font-size: 3vw;
      }

      .div-social .social-icon{
            font-size: 5.64vw;
      }

      .text-copyright {
            margin-top: 5vw;
            margin-bottom: 7vw;

            font-size: 2.5vw;
      }
}
  
