.privacy-total {
    width: 92vw;
    min-height: calc(100vh - 5vw - 5vw - 8.33vw );
    
    margin-top: 4vw;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: auto;
  
    display: flex;
    flex-direction: column;
  
    position: relative; /* Used since we are going to have a position: absolute on a child */
  
    background-color: white;
    border: 0.14vw white solid;
    border-radius: 2.5vw;
    box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  }

  .privacy-main {
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: 4vw;
    margin-right: 4vw;
  }

  .privacy-main .back{
    width: 1.7vw;
    height: 1.7vw;

    position: absolute; /* We want this fixed to main at a distance of the top of 1.4vw and off the left of 1.4vw */
    top: 1.4vw;
    left: 1.4vw;

    color: white; 
    color: #02112E; /* TODO: remove */
    font-size: 1.5vw;
}

  .privacy-main h1 {
    color: #02112E;
    font-size: 3vw;
  }

  .privacy-main h2 {
    color: #02112E;
    font-size: 2.2vw;
  }

  .privacy-main p {
    color: #02112E;
    font-size: 1.2vw;
    text-align: left;
  }
