.add-consumables-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );
    
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
}

.add-consumables-title {
  width: 19vw;
  height: 4.5vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.5));
}

.add-consumables-title h2 {
  margin: auto;

  color:#02112E;
  font-size: 1.3vw;
  font-weight: 600;
}

.add-consumables-main {
  width: auto;

  margin-top: 3.5vw;
  margin-bottom: 6.5vw;
  margin-left: 5vw;
  margin-right: 3.25vw;
}

.add-consumables-main-cards {
  margin-top: 0.5vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.add-tickets-no-consumable-wrapper {
  width: 100%;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw - 15vw);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-tickets-no-consumable-wrapper h3{
  color:#26324B;
  font-size: 1.6vw;
  font-weight: 600;
  text-align: center;
}

.add-button {
  width: 2vw;
  height: 2vw;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.6vw;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.4vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
  
  color: #02112E;
  font-size: 1.3vw;
  font-weight: 600;
  text-align: center;
}

.top-button {
  width: 15.5vw;
  height: 4.3vw;

  padding: 0vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
  cursor:pointer;
}

.top-button:active h4 {
  color: #02112E;
  font-weight: 700;
}

.top-button h4 {
  width: 14.1vw;

  margin-top: auto;
  margin-bottom: auto;

  color: #02112E;
  font-size: 1vw;
  font-weight: 600;
  text-align: center;
}

#create-consumable {
  position: absolute;
  left: 71.5vw;
  top: -2.15vw;
}

.add-consumables-bottom {
  width: 100%;

  padding-right: 5vw;
  padding-left: 5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: absolute;

  bottom: 2vw;
}

.navigation-buttons-total {
  width: 22vw;
  height: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-button{
  height: 3vw;
  width: 10vw;
    
  margin-top: auto; /* Used to center the object */
  margin-bottom: auto; /* Used to center the object */
  margin-right: 0;

  background-color: #02112E;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;
    
  color: white;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.05vw;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
    
  transition: all 0.3s;
}

#previous {
  background-color: white;

  color: #02112E;
}

.navigation-button:active{
  box-shadow: 0 0 0.6vw 0.15vw rgba(2, 17, 46, 0.7);
}