.confirm-ticket-background {
    background-image: url("../../images/ticket-confirm-background.png");

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40vw;
    width: 80vw;
    border-radius: 5vw;
}

.confirm-ticket-info-wrapper {
    width: 95%;
    height: 63%;
    margin-left: 5%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.confirm-ticket-info-wrapper h1 {
    color: #0F6DF9;
    font-family: Montserrat;
    font-size: 4.5vw;

    font-weight: 700;
    text-align: left;
}

.confirm-ticket-dates {
    color: #02112E;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 3vw;

    text-align: left;
    margin: 0;
    margin-bottom: 7%;
}

.confirm-ticket-section-wrapper {
    width: 100%;
    height: 37%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.confirm-ticket-price {
    width: 50%;
    height: 100%;

    border-color: #02112e36 !important;
    border-right: 0.001vh solid;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.buy-button-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.buy-button {
    width: 50%;
    height: 50%;
    background-color: #0F6DF9;
    border-radius: 2vw;
    border: none;
    color: white;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5vw;
    cursor: pointer;
}

.sold-out-button {
  width: 50%;
  height: 50%;
  background-color: #F4F4F477;
  border-radius: 2vw;
  border: none;
  color: black;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 3vw;
  cursor: pointer;
}

.ticket-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

}

.ticket-price p {
    text-align: left;
    color: #02112E;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 2.5vw;
    margin: 0;
}

.ticket-price h1 {
    color: #02112E;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 4vw;
    margin: 0;
}

.payment-modal {
    width: 100vw;
    height: 100vh;
}

.payment-modal-header {
    height: 15vh;
    width: 100vw;
    background-color: #0f6df90c;
}

.payment-modal-info {
    background-color: #0b1c3bcc;
    height: 85vh;
    border-radius: 8vw 8vw 0 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.payment-modal-cardInfo {
  background-color: #1A2338;
  height: 85vh;
  border-radius: 8vw 8vw 0 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}


.exit-button {
    width: 90vw;
    height: auto;
    display: flex;
    margin-top: 3vh;
    justify-content: flex-start;
}

.exit-button img {
    width: 8vw;
    height: auto;
    cursor: pointer;
}

.ticket-price-wrapper {
    width: 70vw;
    height: 7vh;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.399);
}

.ticket-price-wrapper p {
    color: #f2f2f2;
}

.ticket-price-wrapper h2 {
    font-weight: 500;
    color: #f2f2f2;
}

.total-price-wrapper {
    margin-top: 3vh;
    width: 70vw;
    height: 7vh;
    color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.total-price-wrapper p {
    font-weight: 600;
    font-size: 4.5vw;
}

.total-price-wrapper h2 {
    font-weight: 600;
    font-size: 6vw;
}

.buy-div {
    color: #f2f2f2 !important;
    width: 70vw;
    text-align: left;
}

.payment-method-wrapper {
    margin-top: 4vh;
    width: 70vw;
    height: auto;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #f2f2f2;
}

.payment-method-wrapper p {
    font-weight: 400;
    font-size: 4vw;
    margin-bottom: 3vh;
    text-align: left;
    width: 100%;
}

.payment-method-button {
    width: 100%;
    height: 8vh;
    background-color: #f2f2f2;
    border-radius: 2vw;
    border: none;
    color: #02112e;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 4vw;
    cursor: pointer;
    margin-bottom: 3vh;

    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.payment-method-button img {
    width: 8vw;
    height: auto;
    margin-right: 2vw;
}

.mbway-page-wrapper {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70vw;
    height: 100%;
}

.mbway-page-header {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.mbway-page-header h1 {
    margin: 0;
    font-weight: 700;
    font-size: 7vw;
    color: #f2f2f2;
}

.mbway-page-body {
    margin-top: 5vh;
    margin-bottom: 2vh;
    justify-content: center;
}

.mbway-page-body h2 {
    width: 100%;
    font-weight: 500;
    font-size: 4vw;
    color: #f2f2f2;
    text-align: left;
    margin-bottom: 0.5vh;
}

.mbway-page-button {
    background-color: #609cf5;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 5vw;
    margin-top: 10vh;
    width: 80%;
    height: 8vh;
    border: none;
    border-radius: 2vw;
    cursor: pointer;
}

.address-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70vw;

}

.address-page-wrapper h2 {
    margin: 0;
    color: #f2f2f2;
    font-weight: 700;
    font-size: 3.5vw;
    margin-bottom: 0.5vh;
    margin-top: 3vh;
    width: 100%;
    text-align: left;
}

.address-page-wrapper h1 {
    margin: 0;
    color: #f2f2f2;
    font-weight: 700;
    font-size: 5vw;
    margin-bottom: 0.5vh;
    margin-top: 3vh;
    width: 100%;
    text-align: left;
}

.address-page-wrapper p {
    margin: 0;
    color: #f2f2f2;
    font-weight: 500;
    font-size: 4vw;
    width: 100%;
    text-align: left;
}

.address-page-wrapper input {
    width: 100%;
    height: 5vh;
    background-color: #f2f2f2;
    border-radius: 2vw;
    border: none;
    color: #02112e;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 4vw;
    cursor: pointer;
    padding-left: 2vw;
}

.address-page-city-wrapper {
    width: 105%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.address-page-city-wrapper input {
    margin: 0;
    width: 90%;
    height: 5vh;
    background-color: #f2f2f2;
    border-radius: 2vw;
    border: none;
    color: #02112e;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 4vw;
    cursor: pointer;
}

.item-wrapper-left {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
}

.address-page-button {
    background-color: #609cf5;
    color: white;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 5vw;
    margin-top: 6vh;
    width: 80%;
    height: 8vh;
    border: none;
    border-radius: 2vw;
    cursor: pointer;

}

.modal-error-info {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.modal-error-info h1 {
  margin-bottom: 15%;
  font-weight: 650;
  font-size: 5vh;
  color: #f2f2f2;
}

@media screen and (min-width: 650px) {
    .confirm-ticket-background {
        height: 15vw;
        width: 30vw;
        border-radius: 1.5vw;
    }

    .confirm-ticket-info-wrapper {
        width: 95%;
        height: 63%;
        margin-left: 5%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .confirm-ticket-info-wrapper h1 {
        color: #0F6DF9;
        font-family: Montserrat;
        font-size: 1.7vw;

        font-weight: 700;
        text-align: left;
    }

    .confirm-ticket-dates {
        color: #02112E;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 1.1vw;

        text-align: left;
        margin: 0;
        margin-bottom: 7%;
    }

    .confirm-ticket-section-wrapper {
        width: 100%;
        height: 37%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .confirm-ticket-price {
        width: 50%;
        height: 100%;

        border-color: #02112e36 !important;
        border-right: 0.001vh solid;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .buy-button-container {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .buy-button {
        width: 50%;
        height: 50%;
        background-color: #0F6DF9;
        border-radius: 0.75vw;
        border: none;
        color: white;
        font-family: Montserrat;
        font-weight: 700;
        font-size: 1vw;
        cursor: pointer;
    }

    .sold-out-button {
      width: 50%;
      height: 50%;
      background-color: #F4F4F477;
      border-radius: 1.5vw;
      border: none;
      color: black;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 1.1vw;
      cursor: pointer;
    }

    .ticket-price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

    }

    .ticket-price p {
        text-align: left;
        color: #02112E;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 1vw;
        margin: 0;
    }

    .ticket-price h1 {
        color: #02112E;
        font-family: Montserrat;
        font-weight: 700;
        font-size: 1.5vw;
        margin: 0;
    }

    /* Adjust styles for payment modal when screens have width > 650px */

    .payment-modal {
        width: 45vw;
        height: 130vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 52.5%;
        margin-top: 3%;
    }

    .payment-modal-header {
        height: 0vh;
        width: 100vw;
        background-color: #0f6df90c;
    }

    .payment-modal-info {
        background-color: #0b1c3bcc;
        height: 70%;
        width: 90%;
        border-radius: 2vw 2vw 2vw 2vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .payment-modal-cardInfo {
      background-color: #1A2338;
      height: 70%;
      width: 90%;
      border-radius: 2vw 2vw 2vw 2vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .exit-button {
        width: 90%;
        height: auto;
        display: flex;
        margin-top: 3%;
        justify-content: flex-start;
    }

    .exit-button img {
        width: 8%;
        height: auto;
        cursor: pointer;
    }

    .ticket-price-wrapper {
        width: 70%;
        height: 7%;
        color: #333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.399);
    }

    .ticket-price-wrapper p {
        color: #f2f2f2;
    }

    .ticket-price-wrapper h2 {
        font-weight: 500;
        color: #f2f2f2;
    }

    .total-price-wrapper {
        margin-top: 3%;
        width: 70%;
        height: 7%;
        color: #f2f2f2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .total-price-wrapper p {
        font-weight: 600;
        font-size: 2.25vw;
    }

    .total-price-wrapper h2 {
        font-weight: 600;
        font-size: 3vw;
    }

    .buy-div {
        color: #f2f2f2 !important;
        width: 70%;
        text-align: left;
    }

    .payment-method-wrapper {
        margin-top: 4%;
        width: 70%;
        height: auto;
        margin-bottom: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #f2f2f2;
    }

    .payment-method-wrapper p {
        margin-top: 0;
        font-weight: 400;
        font-size: 1.8vw;
        margin-bottom: 7%;
        width: 100%;
        text-align: center;
    }

    .payment-method-button {
        width: 100%;
        height: 7vh;
        background-color: #f2f2f2;
        border-radius: 0.5vw;
        border: none;
        color: #02112e;
        font-family: Montserrat, serif;
        font-weight: 500;
        font-size: 1.75vw;
        cursor: pointer;
        margin-bottom: 5%;

        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .payment-method-button img {
        width: 7%;
        height: auto;
        margin-right: 3%;
    }

    .mbway-page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        height: 80%;

    }

    .mbway-page-header {
        margin-top: 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }

    .mbway-page-header h1 {
        margin: 0;
        font-weight: 700;
        font-size: 2.75vw;
        color: #f2f2f2;
    }

    .mbway-page-body {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        font-size: 2.75vw;
    }

    .mbway-page-body h2 {
        width: 100%;
        font-weight: 500;
        font-size: 2vw;
        color: #f2f2f2;
        margin-bottom: 5%;
        text-align: center;
    }

    .address-page-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 70%;

    }

    .address-page-wrapper h2 {
        margin: 0;
        color: #f2f2f2;
        font-weight: 700;
        font-size: 3.5vw;
        margin-bottom: 0.5%;
        margin-top: 3%;
        width: 100%;
        text-align: left;
    }

    .address-page-wrapper h1 {
        margin: 0;
        color: #f2f2f2;
        font-weight: 700;
        font-size: 5vw;
        margin-bottom: 0.5%;
        margin-top: 3%;
        width: 100%;
        text-align: left;
    }

    .address-page-wrapper p {
        margin: 0;
        color: #f2f2f2;
        font-weight: 500;
        font-size: 4vw;
        width: 100%;
        text-align: left;
    }

    .address-page-wrapper input {
        width: 100%;
        height: 5%;
        background-color: #f2f2f2;
        border-radius: 2vw;
        border: none;
        color: #02112e;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 4vw;
        cursor: pointer;
        padding-left: 2%;
    }

    .address-page-city-wrapper {
        width: 105%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .address-page-city-wrapper input {
        margin: 0;
        width: 90%;
        height: 5%;
        background-color: #f2f2f2;
        border-radius: 2vw;
        border: none;
        color: #02112e;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 4vw;
        cursor: pointer;
    }

    .item-wrapper-left {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
    }

    .address-page-button {
        background-color: #609cf5;
        color: white;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 5vw;
        margin-top: 6%;
        width: 80%;
        height: 8%;
        border: none;
        border-radius: 2vw;
        cursor: pointer;

    }

    .form-control {
        height: 4vw !important;
        font-size: 2vw !important;
    }

    .flag-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17%;
        height: 4vw !important;
    }

  .modal-error-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .modal-error-info h1 {
    font-weight: 650;
    font-size: 3vw;
    color: #f2f2f2;
  }

  .mbway-page-button {
    background-color: #609cf5;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 2.7vw;
    width: 60%;
    height: 8vh;
    margin-bottom: 30%;
  }

  .address-page-wrapper {
    width: 86%;
    margin-bottom: 10vh;
  }

  .address-page-wrapper h2 {
    font-size: 2.1vw;
    margin-bottom: 0.6vh;
    margin-top: 3vh;
  }

  .address-page-wrapper h1 {
    font-size: 2.3vw;
    margin-bottom: 0.3vh;
    margin-top: 3vh;
  }

  .address-page-wrapper p {
    font-size: 1.8vw;
  }

  .address-page-wrapper input {
    height: 2.7vw;
    font-weight: 500;
    font-size: 2vw;
  }
}
