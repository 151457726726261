
.add-edit-button {
    height: 3.1vw;
    width: 18vw;
    
    margin-top: 1.5vw;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #02112E;
    border: 0.14vw #02112E solid;
    border-radius: 0.69vw;
    box-sizing: border-box;
    cursor:pointer;
    
    color: white;
    font-family: 'Montserrat' , sans-serif;
    font-size: 1.1vw;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    
    transition: all 0.3s;
  }
  
.add-edit-button:hover {
    background-color: white;
    
    color: #02112E;
  }
  
.add-edit-button:active {
    box-shadow: 0 0 0.6vw 0.02vw rgba(2, 17, 46, 0.6);
  }

  .add-edit-button:focus {
    outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
  }
  
  .add-edit-button-loading {
    height: 3.1vw;
    width: 18vw;
    
    margin-top: 1.5vw;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  
    background-color: #02112E;
    border: 0.14vw #02112E solid;
    box-sizing: border-box;
    border-radius: 0.69vw;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    transition: all 0.3s;
  }