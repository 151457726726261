.banner {
    width: 88%;

    margin-bottom: -10vw;
    margin-left: auto; /* Used to center the object*/
    margin-right: auto; /* Used to center the object*/

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.banner-left-wrapper {
    width: 70vw;

    text-align: left;
}

.banner-left-wrapper img {
    height: 8vw;

    padding: 0;

    margin-top: 1vw;
    margin-bottom: 3vw;
    margin-left: 0;
    margin-right: auto;    

    z-index: 2;
}

.banner-catchphrase-wrapper {
    margin-top: 3vw;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.banner-left-wrapper h2 {
    margin: 0;

    color: white;
    font-size: 3.2vw;
    font-weight: 400; 
    text-align: left;
}

.banner-left-wrapper h2 b {
    color: #0F6DF9;
    font-weight: 600; 
}

.banner-left-wrapper h4 {
    margin-top: 1.2vw;
    margin-bottom: 3vw;

    color: white;
    font-size: 1.5vw;
    font-weight: 300; 
    text-align: left;
}

.store-buttons-wrapper {
    width: 50%;

    margin-left: 0;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.store-buttons-wrapper a {
    text-decoration: none !important;
}

.banner-right-wrapper img {
    height: 44vw;

    margin: 0;
    padding: 0;
}

.banner-right-wrapper {
    height: 44vw;
}


@media screen and (max-width: 650px) { 

    .banner {
        margin-bottom: 0vw;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .banner-left-wrapper {
        width: 100%;
    }

    .banner-left-wrapper img {
        height: 12vw;
    
        margin-top: 0vw;
        margin-bottom: 4vw;   
    
        z-index: 2;
    }

    .banner-left-wrapper h2 {
        margin-top: 1vw;

        font-size: 6.4vw;
        text-align: center;
    }
    
    .banner-left-wrapper h4 {
        margin-top: 3.4vw;
        margin-bottom: 8vw;
    
        font-size: 3vw;
        text-align: center;
    }
    
    .store-buttons-wrapper {
        width: 80%;
    
        margin-left: auto;
        margin-right: auto;
    
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .banner-right-wrapper {
        height: 8vw;
    }

    .banner-right-wrapper img {
        height: 0vw;
    }

}
