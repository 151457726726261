.event-stats-bar-wrapper {
  height: auto;
  min-height: 3vw;
  width: auto;
  
  margin-top: 0vw;
  margin-left: 0vw;
  margin-right: 0vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.stat-button {
  width: auto;
  max-width: 13vw;
  min-width: 9vw;
  height: 3vw;

  margin-right: 2vw;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.8vw;
  box-shadow: 0 0 0.3vw 0.02vw rgba(0, 0, 0, 0.5);
  cursor:pointer;

  color: #02112E;
  font-size: 1vw;
  font-weight: 700;
}

#highlighted-stat-button {
  background-color: #D4E9FE;
  border: 0.14vw #D4E9FE solid;

  color: #02112E;
}