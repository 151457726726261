.all-events-total {
    width: 92vw;
    min-height: calc(100vh - 5vw - 5vw - 8.33vw );

    margin-top: 4vw;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;

    position: relative; /* Used since we are going to have a position: absolute on a child */

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 2.5vw;
    box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  }

  .all-events-title {
    width: 19vw;
    height: 4.5vw;

    position: absolute;
    left: 5vw;
    top: -2.25vw;

    display: flex;

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  }

  .all-events-title h2 {
    margin: auto;

    color:#02112E;
    font-size: 1.3vw;
    font-weight: 600;
  }

  .top-button {
    width: 15.5vw;
    height: 4.3vw;

    padding: 0vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1vw;
    filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
    cursor:pointer;
  }

  .top-button:active h4 {
    color: #02112E;
    font-weight: 700;
  }

  .top-button h4 {
    width: 14.1vw;

    margin-top: auto;
    margin-bottom: auto;

    color: #02112E;
    font-size: 1vw;
    font-weight: 600;
    text-align: center;
  }

  .all-events-main {
    margin-top: 3.5vw;
    margin-bottom: 2vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .all-events-horiz-container{
    width: "100%";

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .all-events-horiz-container .search-bar-wrapper {
    width: 28vw;
    height: 3vw;

    margin-top: 1vw;
  }

  .all-events-main .no-events-div {
    width: 100%;
    min-height: calc(100vh - 34vw );

    margin-top: "2vw";

    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
  }

  .no-events-div h3{
    color:#26324B;
    font-size: 1.6vw;
    font-weight: 600;
    text-align: center;
  }

a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 650px) {
  .all-events-horiz-container .search-bar-wrapper {
    width: 41.16vw;
    height: 4.41vw;

    margin-top: 1vw;
  }

  .all-events-title {
    width: 30vw;
    height: 7vw;

    position: absolute;
    left: 5vw;
    top: -3vw;

    display: flex;

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  }

  .all-events-title h2 {
    margin: auto;

    color:#02112E;
    font-size: 2.3vw;
    font-weight: 600;
  }
}
