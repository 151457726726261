.collaborator-info-total {
  width: 40vw;
  height: auto;

  margin-top: 2.5vw;

  padding-top: 2vw;
  padding-bottom: 2vw;

  background-color: white;
  border-radius: 2vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.4);
}

.collaborator-info-main{
  margin-bottom: 2vw;
  margin-left: 3vw;
  margin-right: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.collaborator-info-main-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.collaborator-info-main #user-icon {
  margin-left: 0;

  color: #02112E;
  font-size: 5vw;
}

.collaborator-info-text {
  margin-left: 3vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
}

.collaborator-info-text h3 {
  min-width: 10vw;

  margin: 0;

  color: #02112E;
  font-size: 1.75vw;
  font-weight: 700;
}

.collaborator-info-text h4 {
  margin: 0;

  color: #02112E;
  font-size: 1.2vw;
  font-weight: 400;
}

.collaborator-info-text p {
  margin: 0;

  color: #02112E;
  font-size: 0.9vw;
  font-weight: 400;
}

#edit-icon {
  color: #AAAAAA;
  font-size: 1.5vw;

  cursor: pointer;
}

#edit-icon:hover {
  color: #02112E;
}

.collaborator-info-no-events {
  margin: 0vw;

  color:#02112E;
  font-size: 1.2vw;
  font-weight: 600;
}

.collaborator-info-events {
  margin-top: 0.8vw;
}

.collaborator-info-events h3 {
  width: 34vw;

  margin-left: auto;
  margin-right: auto;

  color:#02112E;
  font-size: 1.2vw;
  font-weight: 600;
  text-align: left;
}

.collaborator-info-events-line {
  width: 34vw;
  height: 4vw;

  margin-top: 1.5vw;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1vw;
  box-shadow: 0 0 0.5vw 0.05vw rgba(0, 0, 0, 0.5);
  cursor:pointer;

  color: #02112E;
  font-size: 1vw;
  font-weight: 700;
}

.collaborator-info-events-line h3 {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1.2vw;

  font-size: 1.1vw;
  font-weight: 700;
}

.collaborator-info-transactions{
  width: auto;

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  margin-right: 1vw;
}

.collaborator-info-transactions h4{
  width: max-content;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.7vw;

  font-size: 0.85vw;
  font-weight: 500;
}

#chevron-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;

  color: #02112E;
  font-size: 1.35vw;
}