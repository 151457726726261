.consumable-card-total {
    width: 15vw;
    height: auto; 

    margin-top: 1.5vw;
    margin-right: 1.75vw;

    padding-top: 1.25vw;
    padding-bottom: 1.25vw;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: white;
    border-radius: 0.8vw;
    border: none;
    filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
    cursor: pointer;

    position: relative;
}

#trash-icon {
    position: absolute;
    right: 1vw;
    top: 1vw;

    color: #AAAAAA;
    font-size: 1.4vw;
  
    cursor: pointer;
}

#trash-icon:hover {
    color: #02112E;
}

.consumable-card-title-info-combo {
    width: auto;
    min-width: 5vw;
    height: 2.1vw;

    margin-top: 0.35vw;
    margin-bottom: 0.6vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.consumable-card-title-info-combo h4 {
    margin: 0;

    font-size: 0.75vw;
    font-weight: 400;
}

.consumable-card-title-info-combo h2 {
    margin: 0;
    
    font-size: 1.15vw;
    font-weight: 600;
}

.consumable-card-prices {
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.consumable-card-total p {
    margin: 0.4vw;

    font-size: 0.75vw;
}

.large-size-text {
    margin: 0;
    margin-top: 0.5vw;

    color: #02112E;
    text-align: center;
    font-size: 1.1vw;
    font-weight: 400;

}
