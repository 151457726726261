.register-background{
    height: 100vh;
    min-height: 700px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    background-color: #252F46;
}

.register-main {
    height: 90%;
    width: 92%;

    margin: auto; /* Used to center */

    position: relative; /* Used since we are going to have a position: absolute on a child */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    background-color: white;
    background-image: url(../../../images/back2.png);
    background-position: right center;
    background-size: 100% 100%;
    border: 0 white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1.4vw 0.14vw rgba(0, 0, 0, 0.5);
}

.register-main .back{
    width: 1.73vw;
    height: 1.73vw;

    position: absolute; /* We want this fixed to main at a distance of the top of 1.4vw and off the left of 1.4vw */
    top: 1.4vw;
    left: 1.4vw;

    color: #02112E;
    font-size: 1.52vw;
}

.register-form-wrapper {
    width: 60%;

    margin-left: auto;
    margin-right: auto;
  
}

.register-form-wrapper #text-input-form {
    width: 100%;
  
    margin-left: 0;
}

.register-form-wrapper #password-input-form {
    width: 100%;
  
    margin-left: 0;
}

.date-of-birth-wrapper {
    height: min-content;

    margin-top: 2vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.date-of-birth-wrapper p {
    height: min-content;
    margin: 0;

    color: #02112E;
    font-size: 1.25vw;
    text-align: left;
}

.main-half{
    width: 50%;

    margin-top: auto; /* Used to center de object */
    margin-bottom: auto; /* Used to center de object */

    background-color: transparent;
}

#register-right-half h2 {
    margin-bottom:0;

    color: white;
    font-size: 2.29vw;
}

#register-right-half p {
    width: 60%;

    margin-top: 2.08vw;
    margin-bottom: 2.08vw;
    margin-left: auto; /* Used to center de object */
    margin-right: auto; /* Used to center de object */

    color: white;
    font-size: 1.25vw;
}

#register-right-half #button {
    margin-top: 0;
}

#register-left-half h1 {
    width: 26.4vw;

    margin-bottom: 0.69vw;
    margin-left: auto; /* Used to center de object */
    margin-right: auto; /* Used to center de object */

    font-size: 2.29vw;
}

#register-button-wrapper {
    margin-top: 1vw;
}

.div-account-button {
    width: 26.38vw;
    
    margin-top: 0; /* The margin was forced to zero to eliminate extra white space besides the generated by the 
                     flex distribution */
    margin-bottom: 1.04vw;
    margin-left: auto; /* I used auto to center the element */
    margin-right: auto; /* I used auto to center the element */
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 650px) { 
    .register-background {
        height: stretch; /* This is used so that the view occupies all available space */
        min-height: 200vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */
    }

    .register-main {
        height: calc(195px + 54vh); /* Since percentage measures weren't working we used this calculation that is a 
                                    combination between a fixed measure ( px ) and a measure that varies with the 
                                    height of the viewport ( vh ). */
        min-height: 190vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */

        flex-direction: column;

        background-image: none;  
    }

    .register-main .back{
        top: 6vw;
        left: 6vw;

        color:#02112E;
        font-size: 4vw; 
    }

    .main-half {
        width: 100%;
    }

    #register-left-half {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #register-left-half h1 {
        width: 100%;

        font-size: 5.7vw;
        
    }

    #register-right-half {
        height: 19vh;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #register-right-half h2 {
        margin-top: 0;

        color: #02112E;
        font-size: 6vw;
    }

    #register-right-half p {
        width: 80%;

        color: #02112E;
        font-size: 3.6vw;
    }

    .div-account-button {
        width: 64vw;
        
        margin-bottom: 0s;
    }

}