.live-wrapper {
    height: 2.5vw;
    width: 6.3vw;

    margin-top: 1.4vw;
    margin-left: auto;
    margin-right: auto;

    padding-left: 0.3vw;
    padding-right: 0.3vw;

    background-color: #fff;
    border: 0.14vw #fff solid;
    border-radius: 0.7vw;
    filter: drop-shadow(0 0 0.2vw #888);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  
    color: #02112E;
    font-size: 1.2vw;
}

.live-dot{
    height: 1.6vw;
    width: 1.6vw;

    background-color: #0F6DF9;
    border-radius: 0.8vw;

    animation-name: pulsating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}



@keyframes pulsating {
    0% {background-color: #0F6DF9FF;}
    50% {background-color: #0F6DF9D0;}
    100% {background-color: #0F6DF9FF;}
  }