.add-collaborators-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );
    
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
}

.add-collaborators-title {
  width: 19vw;
  height: 4.5vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.5));
}

.add-collaborators-title h2 {
  margin: auto;

  color:#02112E;
  font-size: 1.3vw;
  font-weight: 600;
}

.add-collaborators-main {
  width: auto;

  margin-top: 3.5vw;
  margin-bottom: 5.5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.add-collaborators-list-wrapper {
  margin:0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-collaborators-bottom {
  width: 100%;

  padding-right: 5vw;
  padding-left: 5vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: absolute;

  bottom: 2vw;
}

.navigation-buttons-total {
  width: 22vw;
  height: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-button{
  height: 3vw;
  width: 10vw;
    
  margin-top: auto; /* Used to center the object */
  margin-bottom: auto; /* Used to center the object */
  margin-right: 0;

  background-color: #02112E;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;
    
  color: white;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.05vw;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
    
  transition: all 0.3s;
}

#previous {
  background-color: white;

  color: #02112E;
}

.navigation-button:active{
  box-shadow: 0 0 0.6vw 0.15vw rgba(2, 17, 46, 0.7);
}

.add-collaborators-horiz-container{
  width: "100%";

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-bar-wrapper {
  width: 28vw;
  height: 3vw;
  
  margin-top: 1vw;
}

.no-events-div{
  width: 100%;
  flex: 1;

  margin-top: "2vw";

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
}