.add-edit-ticket-body-main .form-wrapper {
  width: 70%;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
}

.title-text {
  width: 100%;
  
  margin-bottom: 0.2vw;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 600;
}

.form-wrapper #text-input-form {
  width: 100%;

  margin-left: 0;
}

.add-edit-ticket-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add-edit-ticket-date {
  width: 50%;

  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}

.add-edit-ticket-radio-container {
  margin-bottom: 0.7vw;
}

.add-edit-ticket-price {
  width: 100%;

  margin-bottom: 1vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-edit-ticket-price-single {
  width: 40%;
}

.add-edit-ticket-body-main #optional-text {
  width: 100%;
  
  margin-top: 1.2vw;
  margin-bottom: 0.3vw;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 600;
}

.conditional-price {
  width: 100%;

  margin-top: 1.5vw;
  margin-bottom: 0;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.2vw;
  font-weight: 500;

}

.add-edit-ticket-conditional-date {
  width: 100%;

  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}

.add-edit-ticket-conditional-date h1 {
  height: min-content;

  padding-top: 4%;

  color: #02112E;
  text-align: left;
  font-size: 1.4vw;
  font-weight: 400;
}


#text-time-wrapper {
  width: 35%;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.add-edit-ticket-conditional-date #time-wrapper {
  width: 55%;

  margin-left: 7%;
}

#text-date-wrapper {
  width: 50%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.add-edit-ticket-conditional-date #date-wrapper {
  width: 86%;

  margin-left: 7%;

  padding-left: 0%;
  padding-right: 0%;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
}
