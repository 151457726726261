.confirm-refund-form-wrapper {
  width: 75%;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
}

.confirm-refund-form-wrapper h4 {
  color: #02112E;
  font-size: 1vw;
  font-weight: 400;
  text-align: left;
}

.confirm-refund-form-wrapper #text-input-form {
  width: 100%;

  margin-left: 0;
}