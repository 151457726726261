.event-refund-progress-bar {
  width: 82vw;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  bottom: 2vw;
}

.event-refund-info-text {
  width: 82vw;

  color: #02112E;
  font-size: 1.1vw;
  font-weight: 500;
  text-align: left;
}

.event-refund-value-text {
  margin-top: 2.2vw;

  color: #0F6DF9;
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
}

.event-refund-secondary-info-text {
  width: 82vw;

  margin-top: 2.4vw;

  color: #02112E;
  font-size: 1vw;
  font-weight: 300;
  text-align: center;
}

.event-refund-slider-container {
  height: 18vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event-refund-slider-wrapper {
  width: 60vw;

  margin-top: 3vw;

  margin-left: auto;
  margin-right: auto;
}

.event-refund-update-stock-wrapper {
  width: 82vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navigation-buttons-total {
  width: 22vw;
  height: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.event-refund-lists-container {
  width: 82vw;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.event-refund-list-title {
  color: #02112E;
  font-size: 1.4vw;
  font-weight: 600;
  text-align: center;
}

.event-refund-search-bar-wrapper {
  width: 34vw;
  height: 3vw;

  margin-bottom: 0.5vw;
  margin-left: 3.2vw;
  margin-right: 3.8vw;
}

.event-refund-full-select-wrapper {
  width: 34vw;
  height: 3vw;

  margin-bottom: 0.5vw;
  margin-left: 3.2vw;
  margin-right: 3.8vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.full-select-button {
  width: 16.5vw;
  height: 3vw;

  font-size: 1.05vw;
}

.event-refund-list {
  width: 35vw;
  height: 45vw;

  box-sizing: border-box;

  padding-top: 0.4vw;

  margin-left: auto;
  margin-right: auto;

  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event-refund-loading {
  width: 28vw;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.event-refund-loading p {
  margin-bottom: 0.5vw;

  color: #0F6DF9;
  font-size: 1vw;
  font-weight: 300;
  text-align: left;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.event-refund-button {
  height: 3vw;
  width: 3vw;
    
  margin-top: auto; /* Used to center the object */
  margin-bottom: auto; /* Used to center the object */
  margin-right: 0;
  margin-left: 1vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #02112E;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;
    
  transition: all 0.3s;
}

.event-refund-button-icon {
  color: white;
  font-size: 1.3vw;
}

.event-refund-button:active{
  box-shadow: 0 0 0.6vw 0.15vw rgba(2, 17, 46, 0.7);
}

.regular-button{
  margin-top: auto; /* Used to center the object */
  margin-bottom: auto; /* Used to center the object */
  margin-right: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #02112E;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;
    
  color: white;
  font-family: 'Montserrat' , sans-serif;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
    
  transition: all 0.3s;
}

#alternative {
  background-color: white;

  color: #02112E;
}

.regular-button:active{
  box-shadow: 0 0 0.6vw 0.15vw rgba(2, 17, 46, 0.7);
}

.navigation-button {
  height: 3vw;
  width: 10vw;

  font-size: 1.05vw;
}

.refund-wrapper {
  height: 100%;

  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.refund-feedback-text {
  color: #02112E;
  font-size: 2vw;
  font-weight: 500;
  text-align: center;
}