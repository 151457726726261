.search-bar {
  width: 100%;
  height: 100%;
  
  padding-left: 1vw;
  padding-right: 1vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  border-radius: 0.7vw;
  box-sizing: border-box;

  background-color: white;
  filter: drop-shadow(0 0 0.2vw rgba(0, 0, 0, 0.3));
}

.search-bar-input {
  width: 84%;
  height: 95%;

  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.25vw;
  font-weight: 400;

  box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  border: none;
  outline: none;
}