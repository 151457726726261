.image-wrapper{
  margin-top: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60vw;
}

.image {
  z-index: 2;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.text{
  font-family: Montserrat, serif;
  color: white;
  font-size: 3.5vw;
}

.button {
  background-color: #0F6DF9;
  color: white;
  font-family: Montserrat, serif;
  font-weight: 600;
  font-size: 5vw;
  margin-top: 10vh;
  width: 65%;
  height: 8vh;
  border: none;
  border-radius: 2vw;
  cursor: pointer;
}

button[disabled=disabled], button:disabled{
  background-color: #5698FA;
  color: white;
  font-family: Montserrat, serif;
  font-weight: 600;
  font-size: 5vw;
  margin-top: 10vh;
  width: 65%;
  height: 8vh;
  border: none;
  border-radius: 2vw;
  cursor: pointer;
}

.tail-spin {
  z-index: 3;
}

@media screen and (min-width: 650px) {

  .image-wrapper{
    margin-top: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 23vw;
  }

  .image {
    z-index: 2;
    width: 280px;
    height: 280px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .text{
    font-family: Montserrat, serif;
    color: white;
    font-size: 1.3vw;
  }

  .button {
    background-color: #0F6DF9;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 2.5vw;
    margin-top: 10vh;
    width: 30%;
    height: 9vh;
    border: none;
    border-radius: 2vw;
    cursor: pointer;
  }

  button[disabled=disabled], button:disabled{
    background-color: #5698FA;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 5vw;
    margin-top: 10vh;
    width: 65%;
    height: 8vh;
    border: none;
    border-radius: 2vw;
    cursor: pointer;
  }

}
