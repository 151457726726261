.partners-container {
  height: 100vh;

  display: flex;
}

.partner-wrapper {
  width: 100%;
  height: 15vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.partner-info {
  width: 50vw;

  margin-left: 2vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.partner-title {
  margin: 0;
  margin-bottom: 1.5vw;

  color:#02112E;
  font-size: 2.2vw;
  font-weight: 600;
}

.partner-description {
  margin: 0;

  color:#02112E;
  font-size: 1.2vw;
  font-weight: 400;
  text-align: left;
}

.partner-image {
  width: 10vw;

  margin-right: 2vw;

  margin: auto;

  object-fit: contain;
}