.list-wrapper {
  height: auto;

  padding: 0;

  margin-top: 1vw;
  margin-bottom: 1.5vw;
  margin-left: 0vw;
  margin-right: 0vw;

  display: flex;
  flex-direction: column;
}

.list-button {
  width: 35vw;
  height: 4.6vw;

  padding-right: 1.5vw;

  margin-top: 1vw;
  margin-bottom: 1vw;

  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  box-shadow: 0 0 0.6vw 0.1vw rgba(0, 0, 0, 0.5);
  cursor:pointer;

  color: #02112E;
  font-size: 1vw;
  font-weight: 700;
}

#highlighted-list-button {
  background-color: #D4E9FE;
  border: 0.14vw #D4E9FE solid;
}

#user-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2.1vw;

  color: #02112E;
  font-size: 1.6vw;
}

.list-button h3 {
  width: 14vw;

  margin-top: auto;
  margin-bottom: auto;

  color: #02112E;
  font-size: 1.18vw;
  text-align: start;
}

.list-button p {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1vw;

  color: #02112E;
  font-size: 1.07vw;
  font-weight: 400;
}

.group-tag {
  width: auto;
  max-width: 8vw;
  min-width: 5vw;
  height: 1.5vw;

  margin: 0;

  position: absolute;
  left: -1.8vw;
  top: -0.9vw;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.6vw;
  box-shadow: 0 0 0.6vw 0.1vw rgba(0, 0, 0, 0.5);
  cursor:pointer;

  color: #02112E;
  font-size: 0.8vw;
}