.select-language-wrapper {
    width: 31.25vw;
    height: 13.89vw;
  
    margin-top: -6.94vw; /* Used to center the component since margin:auto wasn't working */
    margin-left: -15.625vw; /* Used to center the component since margin:auto wasn't working */

    padding-bottom: 0.69vw;
    padding-top: 0.69vw;
  
    position: fixed; /* Used to center the component since margin:auto wasn't working */
    left: 50%; /* Used to center the component since margin:auto wasn't working */
    top: 50%; /* Used to center the component since margin:auto wasn't working */

    background: #ffffff;
    border-radius: 1.74vw;
    transition: all .3s ease;
}
  
.select-language-header {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2.08vw;
    margin-right: 2.08vw;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    border-bottom: #02112E solid 0.139vw;
    
    color: #02112E;
}
  
.select-language-header h1 {
    cursor: default;
  
    font-size: 1.39vw;
    font-weight: 600;
}
  
.select-language-header span {
    cursor: pointer;
    transform: rotate(45deg); /* Since we used the '+' character we rotated it to look like a close button */

    font-size: 3.47vw;
}
  
.select-language-body {
    height: 6.25vw;
  
    margin: 0s;
    margin-top: 1.74vw;

    padding-bottom: 1.74vw;
    padding-left: 1.39vw;
    padding-right: 1.39vw;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
.select-language-button {
    width: fit-content; /* To avoid extra unnecessary space */
  
    background-color: transparent;
    border: none;
    cursor:pointer;
  
    font-size: 1.25vw;
    font-weight: 500;
    text-align: left;
}
  
.select-language-button:active {
    color: #1E90FF;
}
  
.background {
    width: 100%; /* Making it occupy the full screen */
    height: 100%; /* Making it occupy the full screen */

    position: fixed; /* Putting it on the top left corner */
    left: 0; /* Putting it on the top left corner */
    top: 0; /* Putting it on the top left corner */
    z-index: 1000;

    background: rgba(0, 0, 0, 0.75);
    transition: all .3s ease;
}


@media screen and (max-width: 650px) { 
    .select-language-wrapper {
        width: 62.5vw;
        height: 27.78vw;
      
        margin-top: -13.89vw; /* Used to center the component since margin:auto wasn't working */
        margin-left: -31.25vw; /* Used to center the component since margin:auto wasn't working */
    
        padding-bottom: 1.4vw;
        padding-top: 1.4vw;

        border-radius: 3.48vw;
    }

    .select-language-header {
        margin-left: 4.16vw;
        margin-right: 4.16vw;
      
        border-bottom: #02112E solid 0.28vw;

    }
      
    .select-language-header h1 {
        font-size: 2.8vw;
    }
      
    .select-language-header span {
        cursor: pointer;
        transform: rotate(45deg); /* Since we used the '+' character we rotated it to look like a close button */
    
        font-size: 6.94vw;
    }
      
    .select-language-body {
        height: 12.5vw;
      
        margin-top: 2.5vw;
    
        padding-bottom: 2.5vw;
        padding-left: 2.8vw;
        padding-right: 2.8vw;
    }
      
    .select-language-button {
        font-size: 2.5vw;
    }
}