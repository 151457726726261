.streak-card-total {
    width: 19vw;
    height: auto;

    margin-top: 2vw;
    margin-right: 2vw;

    padding-top: 1vw;
    padding-bottom: 1vw;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: white;
    border-radius: 0.8vw;
    border: none;
    filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
    cursor: pointer;

    position: relative;
}

#streak-card-total-disabled {
    cursor: not-allowed;
}

#trash-icon {
    position: absolute;
    right: 1vw;
    top: 1vw;

    color: #AAAAAA;
    font-size: 1.1vw;
  
    cursor: pointer;
}

#trash-icon:hover {
    color: #02112E;
}

.streak-card-title-info-combo {
    width: auto;
    min-width: 5vw;
    height: 1.8vw;

    margin-top: 0.35vw;
    margin-bottom: 0.6vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.streak-card-title-info-combo h4 {
    margin: 0;

    font-size: 0.7vw;
    font-weight: 400;
}

.streak-card-title-info-combo h2 {
    margin: 0;
    
    font-size: 1.1vw;
    font-weight: 600;
}

.streak-card-prices {
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.streak-card-total p {
    margin: 0.3vw;

    font-size: 0.7vw;
}
