.store-button {
    width: 12vw;

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;


    background-color: #0F6DF9;
    border: #0F6DF9 solid 0.139vw;
    border-radius: 0.69vw;
    cursor:pointer;
    
    color: white;
    font-family: 'Montserrat' , sans-serif;
    font-size: 1.25vw;
    font-weight: 400;

    transition: all 0.3s;
}

.store-button p {
    text-decoration: none !important; 
}

#alternative {
    background-color: #02112E;
    border: #02112E solid 0.139vw;

    color: white;
}

#apple {
   padding-bottom: 0.28vw; /* The Apple logo without this padding didn't look really centered */
   
   font-size: 1.8vw; /* The two logos have different font sizes in order to feel the same actual size */
}

#google {
   font-size: 1.42vw; /* The two logos have different font sizes in order to feel the same actual size */
}

.store-button:hover{
    background-color: #02112E;
    border: white solid 0.139vw;
    
    color: white;
}

#alternative:hover{
    background-color: white;
    
    color: #02112E;
}

.store-button:active {
    box-shadow: 0 0 1.2vw 0.02vw rgba(255, 255, 255, 0.7);
}

#alternative:active {
    box-shadow: 0 0 1.2vw 0.02vw rgba(2, 17, 46, 0.7);
} 

.store-button:focus {
    outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
}

/* Adaptations to mobile interface */

@media screen and (max-width: 650px) { 
    .store-button {
        width: 30vw;

        border: #0F6DF9 solid 0.3vw;
        border-radius: 2vw;

        font-size: 3.15vw;
    }

    #alternative {
        border: #02112E solid 0.3vw;
    }

    #apple {
        padding-bottom: 0.7vw; /* Lowered since the font was also lowered */
        
        font-size: 4.3vw; 
     }
     
     #google {
        font-size: 3.6vw;
     }

    .store-button:active {
        box-shadow: 0 0 3vw 0.2vw rgba(255, 255, 255, 0.7);
    }
    
    #alternative:active {
        box-shadow: 0 0 3vw 0.2vw rgba(2, 17, 46, 0.7);
    } 

}