.event-stats-card-total {
    width: 82vw;
    height: 20vw;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    position: relative; /* Position is relative since we will use absolute for the beer-info */

    background-color: white;
    border-radius: 2.5vw;
    border: none;
    filter: drop-shadow(0 0 0.35vw #555);
    cursor: pointer;

    font-size: 0.4vw;
}

.event-stats-card-left-wrapper {
    width: 30vw;

    padding-top: 2.4vw;
    padding-bottom: 2.4vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-stats-card-left-wrapper img {
    width: 25.5vw;
    height: 10.5vw;

    margin-left: auto;
    margin-right: auto;

    border-radius: 1vw;
}

.event-stats-card-name-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

.event-stats-card-name-date h1 {
    margin: 0;

    font-size: 1.65vw;
}

.event-stats-card-name-date h3 {
    margin: 0;

    font-size: 1vw;
    font-weight: 400;
}

.event-stats-card-right-wrapper {
    width: 50vw;
    height: 13.5vw;

    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.event-action {
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    font-size: 0.7vw;
    text-decoration: none;

    color: #AAAAAA;
  
    cursor: pointer;
}

.event-action-icon{
    font-size: 2.2vw;
  }
  
.event-action:hover {
    color: #02112E;
}

.event-action h4 {
    margin-bottom: 0.15vw;
    margin-bottom: 0;

    font-weight: 600;
}

#event-stats-icon-disabled {
    cursor: not-allowed;
}

#event-stats-icon-disabled:hover {
    color: #AAAAAA;
}

.event-stats-card-row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-stats-card-info-wrapper {
    width: 15vw;

    display: flex;
    flex-direction: column;
}

.event-stats-card-info-wrapper h3 {
    margin: 0;

    font-size: 1vw;
    font-weight: 400;
}

.event-stats-card-info-wrapper h2 {
    margin: 0;
    margin-top: 0.2vw;

    font-size: 1.45vw;
}
