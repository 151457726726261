.event-sections-wrapper {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-top-right-radius: 7vw;
  border-top-left-radius: 7vw;
  background-color: white;
}

.event-image {
  margin-top: 2vh;
  width: 100vw;
  overflow: hidden;
}

.event-image img {
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  border-top-right-radius: 5vw;
  border-top-left-radius: 5vw;
}

.event-info {
  width: 100vw;
  height: auto;
  margin-top: 4vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.event-page {
  background-color: white;
  min-height: 100vh;
  border-top-right-radius: 5vw;
  border-top-left-radius: 5vw;
  font-family: Montserrat, serif;
}

.loading-wrapper{
  display: flex;
  justify-content: center;
}

.loading {
  position: absolute;
  top: 30%;
}

.event-name {
  color: #02112E;
  width: 80vw;
  font-size: 6.5vw;
  margin-top: -0.7vh;
}

.event-description p {
  margin: 0;
}

.event-date {
  color: #1E90FF;
  font-weight: 450;
  width: 80vw;
  font-size: 2.9vw;
}

.event-hour {
  margin-top: 1vh !important;
  color: #02112E;
  width: 80vw;
  font-weight: 450;
  font-size: 3.5vw;
}

.event-location {
  width: 80vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.location-ping {
  height: 2.8vh;
  width: auto;
}

.event-address {
  margin-left: 2vw;

  color: #02112E;
  font-size: 3vw;
  font-weight: 500;
}

.event-description {
  margin-top: 1vh !important;
  color: #02112E;
  width: 80vw;
  font-size: 2.2vw;
}

.event-description h3 {
  font-weight: 600;
  font-size: 3.5vw;
  margin-left: 1vw;
}

.event-description p {
  margin-top: -0.3vh;
  font-weight: 400;
  font-size: 3.1vw;
}

.sections-wrapper {
  width: 100vw;
  height: auto;
  margin-top: 7vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: white;

}

.sections-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100vw;
}

.section-button-div {
  width: 34vw;
  height: 4vh;

  color: #02112E;
  font-size: 2.3vh;
  font-weight: 450;

  border-bottom: 0.01vh solid;
  border-color: #02112e36;

  background-color: white;
}

.section-button-div:hover {
  cursor: pointer;
  color: #1E90FF;
  border-color: #1E90FF;
}

.section-button-div-highlight {
  width: 34vw;
  height: 4vh;

  color: #1E90FF;
  font-size: 2.6vh;
  font-weight: 400;

  border-bottom: 0.01vh solid;
  border-color: #1E90FF;

  background-color: white;
  cursor: pointer;
}

.prices-section {
  width: 100vw;
  min-height: 20vh;
  height: auto;
  margin-bottom: 10vh;

  background-color: white;
}

.lineup-section {
  width: 100vw;
  min-height: 30vh;
  height: auto;
  margin-bottom: 10vh;

  background-color: white;
}

.tickets-section {
  width: 100vw;
  min-height: 20vh;
  margin-bottom: 10vh;

  background-color: white;
}

.tickets-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5vw;
  margin-left: 6vw;

  overflow-x: scroll;
  scrollbar-width: none;

  background-color: white;
}

.ticket-div {
  margin-right: 7vw;
  width: auto;
  height: auto;
}

.artist-div {
  margin-top: 2vh;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.artist-div p {
  margin: 0;
}

.artist-div h2 {
  font-weight: 500;
  font-size: 4vw;
  margin: 0;
  color: #0F6DF9;
}

.consumable-div {
  margin-top: 2vh;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.consumable-div p {
  font-size: 3.5vw;
  margin: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.bought-ticket-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bought-ticket-div h2 {
  font-size: 3.5vw;
  margin-bottom: 10%;
}

@media screen and (min-width: 650px) {

  .event-info {
    width: 100%;
    height: auto;
    margin-top: 6vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }

  .event-image {
    width: 90vw;
    margin: 8vw 2.5vw 1vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .event-image img {
    width: 100%;
    height: 100%;
    border-radius: 5vw;
  }

  .content-wrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    border-top-right-radius: 7vw;
    border-top-left-radius: 7vw;

    background-color: white;
  }

  .event-sections-wrapper {
    width: 100%;
    height: auto;
    margin-top: 4vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-top-right-radius: 7vw;
    border-top-left-radius: 7vw;
    background-color: white;
  }

  /* width of remaining classes must now be half, but still in vw */

  .event-name {
    width: 40vw;
    font-size: 3vw;
    margin-top: 0;
  }

  .event-hour {
    width: 40vw;
    font-size: 1.4vw;
    margin-bottom: 0.8vw;
  }

  .event-location {
    margin: 0;
    width: 40vw;
  }

  .event-address {
    font-size: 1.3vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.8vw;
  }

  .event-description {
    margin-top: 0;
    width: 40vw;
  }

  .event-description p {
    font-size: clamp(8px, 1.1vw, 24px);
    font-weight: 300;
  }

  .event-description h3 {
    font-size: clamp(8px, 1.5vw, 24px);
    font-weight: 500;
    text-align: left;
  }

  .sections-wrapper {
    width: 50vw;
    height: auto;
    margin-top: 7vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: white;
  }

  .prices-section {
    margin-top: 3vh;
    width: 50vw;
    min-height: 20vh;
    height: auto;
    margin-bottom: 10vh;

    background-color: white;
  }

  .lineup-section {
    margin-top: 3vh;
    width: 50vw;
    min-height: 30vh;
    height: auto;
    margin-bottom: 5vh;

    background-color: white;
  }

  .tickets-section {
    width: 50vw;
    max-height: 500px;
    /* min-height: 20vh; */
    margin-bottom: 10vh;

    background-color: white;
  }

  .tickets-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vw;
    margin-left: 0;

    max-height: 25vw;

    overflow-y: scroll;
    scrollbar-width: none;

    background-color: white;
  }

  .ticket-div {
    margin-right: 0;
    margin-bottom: 3vw;
    width: auto;
    height: auto;
  }

  .artist-div {
    margin-top: 2vh;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .artist-div p {
    margin: 0;
    font-size: 1.5vw;
  }

  .artist-div h2 {
    font-weight: 500;
    font-size: 1.5vw;
    margin: 0;
    color: #0F6DF9;
  }

  .consumable-div {
    margin-top: 2vh;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .consumable-div p {
    font-size: 1.5vw;
    margin: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .sections-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 50vw;
  }

  .section-button-div {
    width: 11vw;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #F1F1F1;
    border-radius: 0.5vw;
    border: none;
    color: black;
    font-family: Montserrat, serif;
    font-weight: 400;
    font-size: 1.3vw;
    cursor: pointer;
  }

  .section-button-div:hover {
    width: 11vw;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #02112E;
    border-radius: 0.5vw;
    border: none;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 400;
    font-size: 1.3vw;
    cursor: pointer;
  }

  .section-button-div-highlight {
    width: 11vw;
    height: 4.5vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #02112E;
    border-radius: 0.5vw;
    border: none;
    color: white;
    font-family: Montserrat, serif;
    font-weight: 400;
    font-size: 1.3vw;
    cursor: pointer;
  }

  .bought-ticket-div h2 {
    font-size: 2vw;
    margin-bottom: 10%;
  }

}
