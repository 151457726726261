.background {
  width: 100%; /* Making it occupy the full screen */
  height: 100%; /* Making it occupy the full screen */

  position: fixed; /* Putting it on the top left corner */
  left: 0; /* Putting it on the top left corner */
  top: 0; /* Putting it on the top left corner */
  z-index: 1000;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.75);
  transition: all .3s ease;
}

.add-edit-collaborator-wrapper {
  width: 31.25vw;

  padding-bottom: 0.69vw;
  padding-top: 0.69vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #ffffff;
  border-radius: 1.74vw;
  transition: all .3s ease;
}

.add-edit-collaborator-header {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2.08vw;
  margin-right: 2.08vw;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  color: #02112E;
}

.add-edit-collaborator-header span {
  cursor: pointer;
  transform: rotate(45deg); /* Since we used the '+' character we rotated it to look like a close button */

  font-size: 3.47vw;
}

.add-edit-collaborator-body {
  height: "100%";

  margin-bottom: 3vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
}

.form-div {
  width: 60%;
  
  margin-top: 0;
  margin-bottom: 0.7vw;
  margin-left: auto;
  margin-right: auto;
}

.add-edit-collaborator-body #text-input-form {
  width: 100%;

  margin-left: 0;
}

.add-edit-collaborator-body #password-input-form {
  width: 100%;

  margin-left: 0;
}
