.profile-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );

  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.profile-title {
  width: 19vw;
  height: 4.5vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.profile-title h2 {
  margin: auto;

  color:#02112E;
  font-size: 1.3vw;
  font-weight: 600;
}

.profile-main {
  margin-top: 3.5vw;
  margin-bottom: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.profile-main h1{
  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 2.1vw;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

.actions-div {
  width: 100%;

  margin-top: 2vw;
  margin-left: 1vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.container-action {
  width: fit-content;
  height: min-content;

  padding: 0;

  margin-left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  align-items: center;
}

.text-action{
  margin: 0;

  color: #02112E;
  font-size: 1.2vw;
  font-weight: 500;
  text-align: left;
  text-decoration: none;

}

.icon-action {
  margin: 0;
  margin-left: 1.2vw;

  color: #02112E;
  font-size: 1vw;
}

.logout-button {
  height: 3.4vw;
  width: 13vw;

  position: absolute;
  right: 4vw;
  bottom: 2vw;

  background-color: white;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;

  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.3vw;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  transition: all 0.3s;
}

.logout-button:active {
  box-shadow: 0 0 0.95vw 0.02vw rgba(2, 17, 46, 0.7);
}

.logout-button:focus {
  outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
}

@media screen and (max-width: 650px) {

  .profile-title {
    width: 32vw;
    height: 9vw;

    position: absolute;
    left: 5vw;
    top: -2.25vw;

    display: flex;

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  }

  .profile-title h2 {
    margin: auto;

    color:#02112E;
    font-size: 3vw;
    font-weight: 600;
  }

  .profile-main {
    margin-top: 9vw;
    margin-bottom: 2vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .profile-main h1{
    color: #02112E;
    font-family: 'Montserrat' , sans-serif;
    font-size: 4vw;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
  }

  .actions-div {
    width: 100%;

    margin-top: 2vw;
    margin-left: 1vw;

    display: flex;
    flex-direction: column;
  }

  .container-action {
    width: fit-content;
    height: min-content;

    padding: 0;

    margin-left: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    align-items: center;
  }

  .text-action{
    margin: 0;

    color: #02112E;
    font-size: 3vw;
    font-weight: 500;
    text-align: left;
    text-decoration: none;

  }

  .icon-action {
    margin: 0;
    margin-left: 1.2vw;

    color: #02112E;
    font-size: 1vw;
  }

  .logout-button {
    height: 7vw;
    width: 24vw;

    margin-bottom: 5vw;

    background-color: white;
    border: 0.3vw #02112E solid;
    border-radius: 0.69vw;
    cursor:pointer;

    color: #02112E;
    font-family: 'Montserrat' , sans-serif;
    font-size: 3vw;
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    transition: all 0.3s;
  }

}
