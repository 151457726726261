.div-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#label-terms {
    margin-top: auto; /* centers the label */
    margin-bottom: auto; /* centers the label */

    font-size: 1.25vw;
}

.error {
    color: #d42000;
    font-size: 0.8vw;
    font-weight: 500;
}

#checkboxError {
  height: min-content;
}

#checkboxError p {
  margin: 0;
}

.disabled-label {
  color: #999A9D;
}

/* RADIO */

.radio-div {
  height: 2.5vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.container {
  width: 47%;
  height: auto;

  margin-top: auto;
  margin-bottom: auto;

  padding-left: 0vw;

  display: block;
  position: relative;

  color: #02112E;
  cursor: pointer;
  font-size: 1.25vw;
  font-weight: 400;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5vw;
  width: 1.5vw;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: 0.15vw #02112E solid;
  box-sizing: border-box;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  bottom: 0.1vw;
	left: 0.11vw;
  height: 1vw;
  width: 1vw;
	border-radius: 50%;
	background: #02112e;
}


/* RADIO END */

#text-area-input {
  width: 100%;
  height: 20vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 2vw;
  margin-left: 0;
}

#text-area-input p {
  margin-top: 0;
  margin-bottom: 0.45vw;

  color: #02112E;
  font-size: 1.25vw;
  font-weight: 400;
  text-align: left;
}

#text-area-input textarea{
  width: 100%;
  height: 20vw;

  margin-left: auto;
  margin-right: auto;

  padding: 0.5vw;
  box-sizing: border-box;

  border: 0.07vw solid #02112E;
  border-radius: 0.69vw;
  outline: none;
  resize: none;

  color: #02112E;
  font-size: 1.25vw;
  font-weight: 600;
}

input {
    font-family: 'Montserrat' , sans-serif;
}

input:-webkit-autofill { /* This code was made to remove the yellow background when we auto fill data */
  box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form {
  height: 3.47vw;
  width: 60%;

  margin-top: 0;
  margin-bottom: 0.7vw;
  margin-left: auto;
  margin-right: auto;

  position: relative;

  overflow: hidden;

}

.form input {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding-top: 1.25vw;

  border: none;
  outline: none;

  color: #02112E;
  font-size: 1.25vw;
  font-weight: 600;
}

.form input:disabled {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding-top: 1.25vw;

  border: none;
  outline: none;

  color: #999A9D;
  font-size: 1.25vw;
  font-weight: 600;
}

.form input:disabled + label {
  border-bottom-color: #999A9D ;
}

.form label {
  height: 100%;
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;

  border-bottom: 0.07vw solid #02112E;
  pointer-events: none;

  color: #02112E;
  font-size: 1.25vw;
  font-weight: 400;
  text-align: left;
}

.form label::after{
  height: 100%;
  width: 100%;

  position: absolute;
  bottom: -0.07vw;
  left: 0;

  border-bottom: 0.21vw solid #02112E;
  content: "";

  transform: translateX(-101%);
  transition: all 0.3s ease;
}

.form .content {
  position: absolute;
  bottom: 0.35vw;
  left: 0;
  transition: all 0.3s ease;
}

.form input:focus + .label .content,
.form input:valid + .label .content{
  margin-bottom: 0.3vw;

  transform: translateY(-150%);

  color: #02112E;
  font-size: 0.85vw;
  font-weight: 400;
}


.form input:not([value=""]):disabled + .label .content{
  margin-bottom: 0.3vw;

  transform: translateY(-150%);

  color: #999A9D;
  font-size: 0.85vw;
  font-weight: 400;
}

.form input:disabled + .label .content{
  color: #999A9D;
}

#static-label .content {
  margin-bottom: 0.3vw;

  transform: translateY(-150%);

  color: #02112E;
  font-size: 0.85vw;
  font-weight: 400;
}

.form input:focus + .label::after{
  transform: translateX(0%);
}

.form input:focus + #static-label::after{
  transform: translateX(-101%);
}


#time-input-form {
  width: 100%;

  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

#time-input-form input{
  margin: 0;

  display: block;
  text-align: center;

  font-size: 1.3vw;
}

#date-input-form {
  width: 100%;

  padding: 0;

  margin-left: 0;
  margin-right: 0;
}

#date-input-form input{
  margin: 0;

  display: block;
  text-align: center;

  font-size: 1.3vw;
}

#phone-input-form {
  width: 100%;
  height: 3.5vw;

  padding: 0;

  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.8vw;
  margin-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#phone-input-form label {
  width: 100%;
  height: auto;

  margin: 0;
  margin-bottom: -0.4vw;

  position: relative;

  border-bottom: none;
  pointer-events: none;

  color: #02112E;
  font-size: 1.25vw;
  font-weight: 400;
  text-align: left;
}

#phone-input-form  #static-label .content {
  transform: translateY(0%);

  color: #02112E;
  font-size: 0.85vw;
  font-weight: 400;
}

#phone-input-form input{
  margin: 0;
  margin-bottom: 0.4vw;

  padding: 0;

  color: #02112E;
  font-size: 1.3vw;
  font-family: "Montserrat";
  font-weight: 400;
  text-align: left;
}

input[type="time"]:not(:valid) {
  color: #26324B;
}

input[type="date"]:not(:valid) {
  color: #26324B;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    margin: 0;
}

/* If we want the underline to stay after the text is filled

.form input:focus + .label::after,
.form input:valid + .label::after{
    transform: translateX(0%);
}

*/

/* Coding the input dynamic OVER */

/* Adaptations to mobile interface */
@media screen and (max-width: 650px) {

    .div-checkbox { /* Had to do a minor adjustment since it wasn't spacing well on the mobile */
        margin-top: 3vw;
        margin-bottom: 2vw;
    }

    #label-terms {
        font-size: 3.5vw;
    }

    .error {
        font-size: 2.3vw;
    }

    #checkboxError { /* Had to do a minor adjustment since it wasn't spacing well on the mobile */
        margin-top: -3vw;
        margin-bottom: 2vw;
    }

    .form {
        height: 11.5vw;
        width: 67vw;
    }

    .form input {
        padding-left: 2.3vw;

        padding-top: 5.5vw;

        font-size: 3.5vw;

    }

    .form label {
        width: 67vw;

        margin-left: 0vw;

        border-bottom: 0.23vw solid #02112E;

        font-size: 3.5vw;
    }

    .form label::after{
        border-bottom: 0.6vw solid #02112E;

        transform: translateX(-180%);
    }

    .form input:focus + .label .content,
    .form input:valid + .label .content{
        margin-bottom: 1vw;

        font-size: 20vh;
    }

    #phone-input-form {
      width: 96%;
      height: 9.5vh;

      padding: 0;

      margin-bottom:0.1vh;
      margin-left: 0.9vh;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    #phone-input-form label {
      width: 100%;
      height: auto;

      margin: 0;
      margin-bottom: -0.4vw;
      position: relative;

      border-bottom: none;
      pointer-events: none;

      color: #02112E;
      font-size: 2vh;
      font-weight: 400;
      text-align: left;
    }

    #phone-input-form  #static-label .content {
    transform: translateY(0%);
    color: #02112E;
    font-size: 1.9vh;
    font-weight: 400;
    }

    #phone-input-form input{
    margin: 0;
    margin-bottom: 0.4vw;

    padding: 0;

    color: #02112E;
    font-size: 2.2vh;
    font-family: "Montserrat";
    font-weight: 400;
    text-align: left;
    }

  #date-input-form {
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  #date-input-form input{
    margin: 0.4vh;
    margin-left: 0.8vh;
    height: 40%;

    font-size: 1.5vh;
  }

  .form .content-date {
    font-size: 1.9vh;
    left: 0;
    transition: all 0.3s ease;
  }

}
