.collaborators-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );
    
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;
  

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.collaborators-title {
  width: 19vw;
  height: 4.5vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.collaborators-title h2 {
  margin: auto;

  color:#02112E;
  font-size: 1.3vw;
  font-weight: 600;
}

.add-button {
  width: 2vw;
  height: 2vw;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.6vw;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.4vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
  
  color: #02112E;
  font-size: 1.3vw;
  font-weight: 600;
  text-align: center;
}

.top-button {
  width: 15.5vw;
  height: 4.3vw;

  padding: 0vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.35));
  cursor:pointer;
}

.top-button:active h4 {
  color: #02112E;
  font-weight: 700;
}

.top-button h4 {
  width: 14.1vw;

  margin-top: auto;
  margin-bottom: auto;

  color: #02112E;
  font-size: 1vw;
  font-weight: 600;
  text-align: center;
}

#create-group {
  position: absolute;
  left: 51vw;
  top: -2.15vw;
}

#add-collaborator {
  position: absolute;
  left: 71.5vw;
  top: -2.15vw;
}

.collaborators-main {
  width: auto;

  display: flex;
  flex-direction: column;
  flex: 1;

  margin-top: 3.5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.collaborators-list-info-wrapper {
  width: 100%;
  height: 100%;

  flex: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.collaborators-info-options-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.collaborators-info-options-wrapper #remove-button {
  width: 40vw;
  height: 4.3vw;

  margin-top: 1.2vw;

  padding: 0vw;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
  cursor:pointer;

  color: #02112E;
  font-size: 1.2vw;
  font-weight: 700;
}

.collaborators-info-options-wrapper #remove-button:active {
  font-weight: 800;
  color: #02112E;
}

#remove-button {
  margin-bottom: 2vw;
  color: #d42000;
}

.collaborators-horiz-container{
  width: "100%";

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.search-bar-wrapper {
  width: 28vw;
  height: 3vw;

  margin-top: 1vw;
} 