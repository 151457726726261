.layout-total {
    width: 92vw;
    min-height: calc(100vh - 15vw );
      
    margin-top: 4vw;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: auto;
  
    display: flex;
    flex-direction: column;
  
    position: relative; /* Used since we are going to have a position: absolute on a child */
  
    background-color: white;
    border: 0.14vw white solid;
    border-radius: 2.5vw;
  }
  
  .layout-title {
    width: 19vw;
    height: 4.5vw;
  
    position: absolute;
    left: 5vw;
    top: -2.25vw;
  
    display: flex;
  
    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.5));
  }
  
  .layout-title h2 {
    margin: auto;
  
    color:#02112E;
    font-size: 1.3vw;
    font-weight: 600;
  }
  
  .layout-main {
    width: auto;
    flex: 1;

    box-sizing: content-box;

    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    margin-top: 3.5vw;
    margin-bottom: 5.5vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }