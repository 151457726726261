.event-card-total {
    margin: 0;

    display: flex;
    flex-direction: column-reverse;
}

.event-card-core {
    width: 26vw;
    height: 20vw;

    margin-top: 1vh;
    margin-left: 1vw;

    background-color: white;
    border-radius: 1vw;
    border: none;
    filter: drop-shadow(0 0 0.4vw #666);

    font-size: 0.4vw;

    z-index: 200;

    display: flex;
    flex-direction: column;
}

.event-card-core img {
    width: 100%;
    height: 50%;

    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;

    z-index: 200;

    object-fit: cover; /* This allows the image to distribute it self without altering the aspect ratio and occupying
                       all of the space */
}

.event-card-text {
    height: 50%;

    padding-top: 0.2vw;
    padding-bottom: 0.55vw;

    z-index: 200;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-card-text h2 {
    margin-top: 0;
    margin-bottom: 0.1vw;

    color: #02112E;
    font-size: 1.35vw;
    font-weight: 700;
}

.event-card-text p {
    margin: 0;

    color: #303C53;
    font-size: 0.95vw;
}

.event-card-location {
    display: flex;
    flex-direction: row;
    justify-content: center;

    cursor: pointer;
}

#map-icon {
    margin-right: 0.5vw;
    padding-top: 0.04vw;

    font-size: 0.85vw;
}

.event-card-location h3 {
    margin-top: 0;
    margin-bottom: 0.4vw;

    font-size: 0.95vw;
    font-style: italic;
    font-weight: 600;
}

.event-card-text button {
    width: 10.5vw;
    height: 2.9vw;

    margin-top: 0.45vw;
    margin-left: auto;
    margin-right: auto;

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;


    background-color: #02112E;
    border: #02112E solid 0.139vw;
    border-radius: 0.4vw;
    cursor:pointer;

    font-family: 'Montserrat' , sans-serif;

    transition: all 0.3s;
}

.event-card-text button:focus {
    outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
}

.event-card-text button:active {
    box-shadow: 0 0 0.5vw 0.02vw rgba(2, 17, 46, 0.7);
}

.event-card-text button h4 {
    width: 100%;

    margin-top: 0;
    margin-bottom: 0.04vw;

    color: white;
    font-size: 0.88vw;
    font-weight: 700;
}

.event-card-text button p {
    width: 100%;

    margin-top: 0.04vw;
    margin-bottom: 0;

    color: white;
    font-size: 0.73vw;
    font-style: italic;
    font-weight: 400;
}

.beer-info {
    width: 6.1vw;
    height: 2.7vw;

    padding-left: 0.35vw;
    padding-right: 0.35vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: white;
    border-radius: 0.4vw;
    filter: drop-shadow(0 0 0.1vw #666);

    font-size: 0.4vw;

    z-index: 2000; /* This index is used so that the beer info is on top of the event-card-core */

}

.beer-info-text {
    margin-top: auto;
    margin-bottom: auto;
}

.beer-info img {
    width: 1.75vw;
    height: 1.75vw;

    margin-top: auto;
    margin-bottom: auto;
}

.beer-info-text h4 {
    width: 4vw;

    margin: 0;

    font-weight: 700;
    font-size: 0.9vw;
    text-align: center;
}

.beer-info-text p {
    width: 4vw;

    margin: 0;

    font-weight: 400;
    font-size: 0.8vw;
    text-align: center;
}


@media screen and (max-width: 650px) {
    .event-card-core {
        width: 70vw;
        height: 48vw;

        border-radius: 1.7vw;
        filter: drop-shadow(0 0 0.6vw #666);

        font-size: 0.68vw;
    }

    .event-card-core img {
        border-top-right-radius: 1.7vw;
        border-top-left-radius: 1.7vw;
    }

    .event-card-text {
        padding-top: 0.17vw;
        padding-bottom: 2vw;
    }

    .event-card-text h2 {
        font-size: 3.8vw;
    }

    .event-card-text p {
        font-size: 2vw;
    }

    #map-icon {
        margin-right: 0.85vw;
        padding-top: 0.068vw;

        font-size: 2.12vw;
    }

    .event-card-location h3 {
        margin-bottom: 0.68vw;

        font-size: 2vw;
    }

    .event-card-text button {
        width: 30vw;
        height: 8vw;

        border: #02112E solid 0.236vw;
        border-radius: 0.68vw;
    }

    .event-card-text button h4 {
        width: 25vw;

        font-size: 2.5vw;
    }

    .event-card-text button p {
        margin-top: 0;

        font-size: 2vw;
    }

    .beer-info {
        width: 14vw;
        height: 6.9vw;

        padding-left: 0.595vw;
        padding-right: 0.595vw;

        border-radius: 0.68vw;
        filter: drop-shadow(0 0 0.3vw rgb(88, 86, 86));
        z-index: 2000;

        top: -1.7vw;
        left: -2.99vw;
    }

    .beer-info img {
        width: 4.37vw;
        height: 4.37vw;
    }

    .beer-info-text h4 {
        width: 10vw;

        font-size: 2.25vw;
    }

    .beer-info-text p {
        width: 10vw;

        font-size: 2vw;
    }

}
