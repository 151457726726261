.event-statistics-total {
  width: 92vw;
  height: max-content;

  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: #FFFFFF;
  border: 0.14vw #FFFFFF solid;
  border-radius: 2.5vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.event-statistics-main {
  height: max-content;
  min-height: 30vw;

  padding: 0;

  margin-bottom: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.event-statistics-main img {
  width: 34vw;
  height: 14.57vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  border-radius: 1.4vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.event-statistics-title {
  width: 50%;
  height: 12.75vw;

  margin: 0;
  margin-left: 50%;
}

.event-statistics-title h1 {
  margin: 0;
  margin-top: 1.4vw;

  color: #02112E;
  font-size: 4vw;
  font-weight: 700;
}

.event-statistics-title h3 {
  margin: 0;
  margin-top: 0.8vw;

  color: #02112E;
  font-size: 1.7vw;
  font-weight: 400;
}

.single-page-container {
  height: auto;

}

.stat-card-container {
  width: 100%;
  height: 10.5vw;

  margin-top: 2vw;
  margin-bottom: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stat-card {
  width: 27%;
  height: 8.5vw;

  padding: 1.2vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #FFFFFF;
  border: 0.14vw #FFFFFF solid;
  border-radius: 1vw;
  filter: drop-shadow(0 0 0.35vw #888);
}

.stat-card .value-container {
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-card h1 {
  width: 100%;

  margin: 0;

  padding: 0;

  color: #02112E;
  font-size: 2.8vw;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
}

.stat-card p {
  width: 100%;

  margin: 0;

  color: #02112E;
  font-size: 1.5vw;
  font-weight: 400;
  text-align: left;
}

.stat-line {
  width: 100%;
  height: 2.5vw;

  margin-top: 0.5;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stat-name{
  width: 100%;
  height: 2.5vw;

  margin-top: 20px;
  margin-bottom: 15px;
  gap: 20px;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.stat-title {
  width: 30%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-value {
  width: 18%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-total {
  width: 20%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-title h3 {
  padding: 0;
  margin: 0;

  color: #02112E;
  font-size: 1.5vw;
  font-weight: 500;
  text-align: left;
}

.stat-title h4 {
  padding: 0;
  margin: 0;
  margin-left: 3vw;

  color: #02112E;
  font-size: 1.3vw;
  font-weight: 500;
  text-align: left;
}

.stat-value h3 {
  padding: 0;
  margin: 0;

  color: #02112E;
  font-size: 1.5vw;
  font-weight: 500;
  text-align: center;
}

.stat-value p {
  padding: 0;
  margin: 0;

  color: #02112E;
  font-size: 1.2vw;
  font-weight: 400;
  text-align: center;
}

.stat-total h2 {
  padding: 0;
  margin: 0;

  color: #02112E;
  font-size: 1.8vw;
  font-weight: 700;
  text-align: right;
}

.stat-total h3 {
  padding: 0;
  margin: 0;

  color: #02112E;
  font-size: 1.4vw;
  font-weight: 600;
  text-align: right;
}

#new-line {
  margin-top: 2.5vw;
}

.leaderboard {
  margin-top: 2.5vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.leaderboard-stats {
  margin-top: 1vw;
  margin-bottom: 1.5vw;

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2%;
  padding-right: 2%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leaderboard-stats h4{
  margin-top: 0;
  margin-bottom: 0;

  color: #02112E;
  font-size: 1.2vw;
  font-weight: 400;
  text-align: center;
}


.leaderboard-stats .leaderboard-values h4{
  width: 25%;
}


.leaderboard-entry {
  width: 100%;
  height: 4vw;

  margin-bottom: 1.5vw;

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2%;
  padding-right: 2%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  align-items: center;

  background-color: #FFFFFF;
  border: 0.14vw #FFFFFF solid;
  border-radius: 1.1vw;
  filter: drop-shadow(0 0 0.35vw #888);
}

.leaderboard-entry h4{
  margin: 0;
  padding: 0;

  color: #02112E;
  font-size: 1.6vw;
  font-weight: 600;
  text-align: left;
}

.leaderboard-values {
  width: 45%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leaderboard-entry .leaderboard-values p{
  width: 25%;

  margin: 0;
  padding: 0;

  color: #02112E;
  font-size: 1.6vw;
  font-weight: 500;
  text-align: center;
}

.leaderboard-entry .leaderboard-values h4{
  width: 25%;

  margin: 0;
  padding: 0;

  color: #1E90FF;
  font-size: 1.6vw;
  font-weight: 600;
  text-align: center;
}

.leaderboard .leaderboard-entry:last-child{
  margin-bottom: 0;
}

#completed-streaks-title{
  width: 11.5vw;

  text-align: center;
}

#completed-streaks{
  width: 11.5vw;

  margin: 0;
  padding: 0;

  color: #1E90FF;
  font-size: 1.6vw;
  font-weight: 600;
  text-align: center;
}

.event-stats-days-bar-wrapper{
  width: 100%;

  margin-top: 1vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.event-statistics-loading {
  width: 100%;
  height: 20vw;

  margin-top: 5vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 2vw;

  margin-right: 2vw;

  margin: auto;

  object-fit: contain;
}
