.add-basic-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );

  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  position: relative; /* Used since we are going to have a position: absolute on a child */

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
}

.add-basic-title {
  width: 19vw;
  height: 4.5vw;

  position: absolute;
  left: 5vw;
  top: -2.25vw;

  display: flex;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 1.4vw;
  filter: drop-shadow(0 0 0.3vw rgba(0, 0, 0, 0.5));
}

.add-basic-title h2 {
  margin: auto;

  color:#02112E;
  font-size: 1.3vw;
  font-weight: 600;
}

.add-basic-main {
  width: auto;
  height: auto;

  margin-top: 4.5vw;
  margin-bottom: 2vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.add-basic-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-basic-image-container {
  width: 37vw;
  height: 20vw;

  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  font-size: 1vw;
}

.add-basic-image-box {
  width: 18vw;
  height: 9.74vw;

  margin-bottom: 1vw;
}

.add-basic-preview-container {
  width: 100%;
  height: 100%;

  position: relative;

  border-radius: 0.7vw;
}

.add-basic-image-preview {
  width: 100%;
  height: 100%;

  border-radius: 0.7vw;
  border-color: transparent;
}

.add-basic-image-button {
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  background: #F9FBFC;
  border: dashed 0.1vw #02112E;
  border-radius: 0.7vw;
  cursor:pointer;
}

.add-basic-image-button-disabled {
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  background: #EEE;
  border: dashed 0.1vw #02112E;
  border-radius: 0.7vw;
  cursor: not-allowed;
}
.plus-icon {
  font-size: 1.8vw;
}

.main-container {
  width: 5.5vw;
  height: 1.6vw;

  position: absolute;
  left: -2vw;
  top: -0.8vw;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.4vw;
  filter: drop-shadow(0 0 0.2vw rgba(0, 0, 0, 0.5));
}

.main-container h1 {
  color:#02112E;
  font-size: 0.92vw;
  font-weight: 600;
}

.trash-container {
  width: 1.8vw;
  height: 1.6vw;

  position: absolute;
  right: -0.9vw;
  top: -0.8vw;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 0.4vw;
  filter: drop-shadow(0 0 0.2vw rgba(0, 0, 0, 0.5));
}

.trash-container .trash-icon {
  color: #AAAAAA;
  font-size: 1.05vw;

  cursor: pointer;
}

.trash-container .trash-icon:hover {
  color: #02112E;
}

.main-form-wrapper{
  width: 52%;
}

.main-form-wrapper #text-input-form {
  width: 100%;

  margin-left: 0;
}

.date-title-div {
  margin-top: 1.6vw;
  margin-bottom: 0.6vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-title-buttons {
  width: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.date-title {
  height: min-content;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.8vw;
}

.date-container {
  margin-bottom: 1.8vw;
}

.date-semi-title {
  height: min-content;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.5vw;
}

.add-basic-form-date-wrapper {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-basic-form-date {
  width: 42%;
  height: auto;

  margin-bottom: 0;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.date-type {
  padding-top: 0.3vw;
  padding-bottom: 0;

  color: #02112E;
  font-size: 1.3vw;
  font-weight: 500;
}

.add-basic-form-date #time-wrapper{
  width: 17%;
  height: auto;
}

.add-basic-form-date #date-wrapper{
  width: 33%;
  height: auto;
}

.poster-title-wrapper {
  margin-top: 0.5vw;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#small {
  width: 2.5vw;
  height: 2.5vw;

  margin-left: 1.5vw;

  border-radius: 0.6vw;

  font-size: 1.25vw;
}

.poster-title {
  height: min-content;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 500;
}

.add-basic-form-poster-wrapper {
  width: 100%;

  margin-bottom: 1.5vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.artist-wrapper {
  width: 18%;
}

.artist-wrapper #text-input-form {
  width: 100%;
}



#form-description {
  width: 100%;
  height: 20vw;

  margin-top: 0;
}

.date-title-buttons .add-extra {
  width: 3vw;
  height: 3vw;

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.8vw;

  background-color: #ffffff;
  border: 0.14vw #ffffff solid;
  box-shadow: 0 0 0.3vw 0.05vw rgba(0, 0, 0, 0.3);
  border-radius: 0.7vw;
  cursor:pointer;

  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.45vw;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.date-title-buttons .add-extra:active {
  box-shadow: 0 0 0.5vw 0.02vw rgba(0, 0, 0, 0.45);
}

#filler {
  width: 3vw;
  height: 3vw;

  margin-top: auto;
  margin-bottom: auto;

  background-color: transparent;
  border: none;
  box-shadow: none;
  color: transparent;
  cursor: default;
}

.add-basic-bottom {
  width: 100%;

  margin-top: 4vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-button{
  height: 3vw;
  width: 10vw;

  margin-top: auto; /* Used to center the object */
  margin-bottom: auto; /* Used to center the object */
  margin-right: 0;

  background-color: #02112E;
  border: 0.14vw #02112E solid;
  border-radius: 0.69vw;
  cursor:pointer;

  color: white;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.05vw;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  transition: all 0.3s;
}

.navigation-button:active{
  box-shadow: 0 0 0.6vw 0.15vw rgba(2, 17, 46, 0.7);
}