.add-edit-streak-body-main .form-wrapper {
  width: 78%;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
}

.consumables-title-wrapper {
  height: 2.5vw;

  margin-top: 0.5vw;

  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-edit-streak-body-main .title-text {
  width: 100%;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 600;
}

.consumables-buttons {
  width: auto;

  padding: 0;

  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.consumables-buttons .add-extra {
  width: 3vw;
  height: 3vw;

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.8vw;

  background-color: #ffffff;
  border: 0.14vw #ffffff solid;
  box-shadow: 0 0 0.3vw 0.05vw rgba(0, 0, 0, 0.3);
  border-radius: 0.7vw;
  cursor:pointer;

  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.45vw;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.consumables-buttons .add-extra:active {
  box-shadow: 0 0 0.5vw 0.02vw rgba(0, 0, 0, 0.45);
}

#small {
  width: 2.5vw;
  height: 2.5vw;

  margin: 0;
  margin-left: 1.5vw;

  border-radius: 0.6vw;

  font-size: 1.25vw;
}

.form-wrapper #text-input-form {
  width: 100%;

  margin-left: 0;
}

.consumable-line {
  margin-bottom: 0.2vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.consumable-line #text-input-form {
  width: 50%;

  margin: 0;
}

.consumable-line #select-input-div {
  width: 40%;

  margin: 0;
}

#prize-line #select-input-div {
  width: 33%;
}

#prize-line #text-input-form  {
  width: 27%;
}

#prize-line #text-input-form:last-child {
  width: 35%;
}

.error-text {
  height: 1.4vw;

  margin-bottom: -1.3vw;

  justify-content: center;
  align-items: center;

  color: #d42000;
  font-size: 0.8vw;
  font-weight: 500;
}