.landing-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Used so that the footer remains at the bottom of the page */
}

.div-tut-and-action {
    padding-top: 12vw;
    padding-bottom: 6vw;

    border: none;
    background-image: url("../../images/landingPageBackground.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    z-index: -1;
}

.call-to-action {
    height: auto; /* I used height auto to force the height to be the minimum possible for the desired margins and 
    inside elements size */

    margin-top: 3vw;
    margin-bottom: 4vw;

    color: white;
    font-size: 2.78vw;
    font-style: italic;
    font-weight: 800; 
}

.div-store-buttons {
  width: 40%;

  margin-top: 0;
  margin-bottom: 3.47vw;
  margin-left: auto; /* Used to center the element */
  margin-right: auto; /* Used to center the element */

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.div-store-buttons a {
    text-decoration: none;
}

.all-events-link {
    width: min-content;

    color: #02112E;
    font-size: 0.75vw;
    text-decoration: none;
}

.all-events-link:hover  {
    color: #1E90FF;
}

.all-events-link:active  {
    color: #1E90FF;
}

.carousel-container {
    height: 18vw;

    margin-bottom: 2vw;

    padding-top: 3vw;
    padding-bottom: 5vw;
    padding-left: 2.5vw;
}

.react-multi-carousel-dot button {
    width: 0.83vw !important; /* Had to use the tag !important to force the change */
    height: 0.83vw !important; /* Had to use the tag !important to force the change */

	margin-right: 0.417vw !important; /* Had to use the tag !important to force the change */

    padding: 0.347vw !important; /* Had to use the tag !important to force the change */

    border-width: 0 !important; /* Had to use the tag !important to force the change */

    background-color: #DEDEDE;
    border: none !important; /* Had to use the tag !important to force the change */
}

.react-multi-carousel-dot button:hover:active {
	background: #02112E;
    border: none !important; /* Had to use the tag !important to force the change */
}

.react-multi-carousel-dot--active button {
	background: #02112E;
    border: none !important; /* Had to use the tag !important to force the change */
}

.react-multiple-carousel__arrow {
    background: transparent !important; /* Had to use the tag !important to force the change */
}

.react-multiple-carousel__arrow::before {
    color: #02112E !important; /* Had to use the tag !important to force the change */
    font-size: 2vw !important; /* Had to use the tag !important to force the change */
}

.react-multiple-carousel__arrow--left {
    left: calc(0.85vw - 10px) !important; /* Had to use the tag !important to force the change */
    padding-left: 0 !important; /* Had to use the tag !important to force the change */
}

.react-multiple-carousel__arrow--right {
    right: calc(0.85vw - 10px) !important; /* Had to use the tag !important to force the change */
}

.div-explanation-app {
    width: 80vw;

    margin-bottom: 4vw;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div-explanation-app-right-wrapper {
    margin-left: 7vw;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mockup-image {
    width: 22vw;

    object-fit: contain;
}

.app-offer {
    height: 3.3vw;

    margin-top: -4.1vw;
    margin-bottom: 0.8vw;
    margin-left: 0vw;

    color: #02112E;
    font-size: 2.5vw;
    font-weight: 400;
    text-align: left;
}

.div-explanation-app-title {
    height: min-content;

    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div-explanation-app-title img {
    width: 2vw;
    height: 1.7vw;

    object-fit: contain;
}

.div-explanation-app-title h3 {
    margin: 0;
    margin-left: 1.2vw;
    
    padding-bottom: 0.2vw;

    color: #0F6DF9;
    font-size: 2.6vw;
    font-weight: 700;
    text-align: left;
}

.explanation-text {
    width: 54vw;

    margin-left: 3vw;

    color: #02112E;
    font-size: 1.4vw;
    font-weight: 300;
    text-align: left;

    white-space: pre-line;
}

.explanation-line {
    width: 0.11vw;

    position: absolute;
    left: 0.9vw;

    background-color: #02112E;
    border: solid #02112E 0vw;
    border-radius: 0.05vw;
}

#line-one {
    height: 29.9vw;

    top: 13.8vw;
}

#line-two {
    height: 31.6vw;

    top: 12.3vw;
}

#line-three {
    height: 33.8vw;

    top: 12vw;
}

/* Adaptations to mobile interface */

@media screen and (max-width: 650px) { 
    .call-to-action {
        margin-top: 12vw;

        font-size: 6.15vw;
    }

    .div-store-buttons {
        margin-top: 8vw;
        margin-bottom: 8vw;

        width: 81%; /* Used this value to be consistent with banner value which was 90% x 90% = 81% */

    }

    .carousel-container {
        height: 35vw;

        margin-bottom: 6vw;
        
        padding-top: 2.5vw;
        padding-bottom: 9vw;
        padding-left: 4.6vw;
    }

    .react-multi-carousel-dot button {
        width: 2vw !important; /* Had to use the tag !important to force the change */
        height: 2vw !important; /* Had to use the tag !important to force the change */
    
        margin-right: 1vw !important; /* Had to use the tag !important to force the change */
    
        padding: 0.72vw !important; /* Had to use the tag !important to force the change */
    }

    .all-events-link {
        font-size: 2vw;
    }

    .div-tut-and-action {
        padding-top: 15vw;
        padding-bottom: 15vw;

        background-image: url("../../images/landingPageBackgroundMobile.png");
    }
    
    .div-explanation-app {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        align-items: center;
    }

    .div-explanation-app-right-wrapper {
        margin-left: 0vw;

        align-items: center;
    }

    .mockup-image {
        width: 45vw;

        margin-bottom: 3vw;
    }

    #mockup-events {
        margin-bottom: 2vw;
    }

    .app-offer {
        height: 0vw;
        font-size: 0vw;
    }

    .explanation-line {
        height: 0vw !important;
    }

    .div-explanation-app-title {
        margin: 0;

        justify-content: center;
    }

    .div-explanation-app-title img {
        width: 0vw;
        height: 0vw;
    }
    
    .div-explanation-app-title h3 {
        width: 100vw;

        margin: 0;
        margin-top: 2vw;
        margin-left: 0vw;
        
        padding-bottom: 0.2vw;
    
        color: #0F6DF9;
        font-size: 4.5vw;
        font-weight: 700;
        text-align: center;
    }
    
    .explanation-text {
        width: 82vw;
    
        margin-left: 0vw;
    
        color: #02112E;
        font-size: 3vw;
        font-weight: 300;
        text-align: center;
    
        white-space: pre-line;
    }

    .partners-title {
        font-size: 4vw;
    }

    .partner-image {
        width: 20vw;
    }

}