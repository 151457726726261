.created-successfully-total {
  width: 92vw;
  min-height: calc(100vh - 5vw - 5vw - 8.33vw );
    
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  border: 0.14vw white solid;
  border-radius: 2.5vw;
  box-shadow: 0 0 1vw 0.1vw rgba(0, 0, 0, 0.5);
}

.created-successfully-total div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.created-successfully-total h2 {
  font-size: 2.3vw;

  font-weight: 400;
  
}
