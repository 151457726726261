.overall-container {
    width: 92%;
  
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
  
    margin-bottom: 0.7vw;
    margin-left: auto;
    margin-right: auto;
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    background-color: white;
    border-radius: 1vw;
    box-shadow: 0 0 0.3vw 0.05vw rgba(2, 17, 46, 0.3);
} 
  
.user-info-wrapper {
    width: 58%;
    height: 4vw;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
  
.ticket-info-wrapper {
    width: 29%;
    height: 4vw;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
  
.user-info-wrapper h3 {
    margin: 0;
  
    color: #0F6DF9;
    font-size: 1.1vw;
    font-weight: 600;
    text-align: left;
}
  
.user-info-wrapper h4 {
    margin: 0;
  
    color: #02112E;
    font-size: 0.9vw;
    font-weight: 400;
    text-align: left;
}
  
.ticket-info-wrapper h3 {
    margin: 0;
  
    color: #0F6DF9;
    font-size: 1.1vw;
    font-weight: 600;
    text-align: right;
}
  
.ticket-info-wrapper h4 {
    margin: 0;
  
    color: #02112E;
    font-size: 0.9vw;
    font-weight: 400;
    text-align: right;
}