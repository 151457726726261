.auth-button {
    height: 3.47vw;
    width: 13.4vw;
    
    margin-top: auto; /* Used to center the object */
    margin-bottom: auto; /* Used to center the object */
    margin-left: 0;

    background-color: #02112E;
    border: 0.14vw #02112E solid;
    border-radius: 0.69vw;
    box-sizing: border-box;
    cursor:pointer;
    
    color: white;
    font-family: 'Montserrat' , sans-serif;
    font-size: 1.2vw;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    
    transition: all 0.3s;
}

.auth-button:hover {
    background-color: white;
    
    color: #02112E;
}

.auth-button:active {
    box-shadow: 0 0 0.95vw 0.02vw rgba(2, 17, 46, 0.7);
}

.auth-button:focus {
    outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
}

#alternative-color-scheme {
    background-color: white;
    border: 0.139vw white solid;
    
    color: #02112E;
}

#alternative-color-scheme:hover {
    background-color: #02112E;
    
    color: white;
}

#line-only {
    background-color: transparent;
    border: 0.139vw white solid;
    
    color: white;
}

#line-only:hover {
    background-color: transparent;
    
    color: white;
}

#alternative-color-scheme:active {
    box-shadow: 0 0 0.95vw 0.02vw rgba(255, 255, 255, 0.7);
}

.auth-button-loading {
    height: 3.47vw;
    width: 13.4vw;
    
    margin-top: auto; /* Used to center the object */
    margin-bottom: auto; /* Used to center the object */
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #02112E;
    border: 0.14vw #02112E solid;
    border-radius: 0.69vw;
    box-sizing: border-box;
    
    color: white;

    transition: all 0.3s;
}

@media screen and (max-width: 650px) { 
    .auth-button {
        width: 30vw;
        height: 9.5vw;
      
        border: 0.278vw #02112E solid;
        border-radius: 1.4vw;
        
        font-size: 3.1vw;
    }

    .auth-button-loading {
        width: 30vw;
        height: 9.5vw;
      
        border-radius: 1.4vw;
    }

    .auth-button:active {
        box-shadow: 0 0 2.2vw 0.2vw rgba(2, 17, 46, 0.7);
    } 

    #alternative-color-scheme {
        background-color: #02112E; /* Had to change the alternative color scheme since the background is always white */
        border: 0.278vw #02112E solid; /* Had to change the alternative color scheme since the background is always white */
        
        color: white; /* Had to change the alternative color scheme since the background is always white */
    }
    
    #alternative-color-scheme:hover {
        background-color: white; /* Had to change the alternative color scheme since the background is always white */
        
        color: #02112E; /* Had to change the alternative color scheme since the background is always white */
    }

    #alternative:active {
        box-shadow: 0 0 2.2vw 0.2vw rgba(255, 255, 255, 0.7);
    } 

    #different-size{
        height: 7.7vw;
        width: 22.2vw;

        border: 0.27vw #02112E solid;
        border-radius: 1.5vw;

        font-size: 2.8vw;
    }

    #line-only{
        height: 5vw;
        width: 21vw;

        background-color: transparent;
        border: 0.139vw white solid;
        border-radius: 1.5vw;
        
        color: white;

        font-size: 2vw;
    }

}