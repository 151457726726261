.nav-bar {
    width: 100%; /* The use of percent makes the code more responsive to different layouts */
    height: 9vw;

    /* background-image: url("../../images/navbar.png"); */
    background-repeat: no-repeat;
    background-size: contain;

}

.logo-clear {
    height: 5vw;
    width: auto;
    margin: 0;
}

.events-button {
    font-size: 2.5vw;
    color: white;
    background-color: #02112E;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
}

.events-button:hover {
    color: #02112E;
    background-color: white;
}

.events-button-highlight {
    font-size: 2.7vw;
    color: #02112E;
    background-color: white;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
}

.profile-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.right-navbar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profile-img img {
    height: 6.5vw !important;
}

.nav-bar-wrapper{
    width: 90%; /* The use of percent makes the code more responsive to different layouts */
    height: 100%; /* Used to force the wrapper to take all the space defined on .nav-bar */

    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.image-link {
    margin-top: auto; /* Used to center the object */
    margin-bottom: auto; /* Used to center the object */
    margin-left: 0;
}

.nav-bar img {
    height: 3.2vw;
}

.buttons-wrapper {
    margin-top: auto; /* Used to center the object */
    margin-bottom: auto; /* Used to center the object */
    margin-right: 0;

    padding-top: 0.5vw;

    height: 100%; /* Used to force the wrapper to take all the space defined on .nav-bar */
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-bar .language-button {
    margin-top: auto; /* Used to center the object */
    margin-bottom: auto; /* Used to center the object */

    background-color: transparent;
    border: none;
    cursor:pointer;

    color: white;
    font-size: 1.11vw;
    font-weight: 600;
    text-decoration: none;

    transition: all 0.3s;
}

.page-selector-special{
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 1vw;
    padding-right: 1vw;

    margin-left: 3vw;
    margin-right: 3vw;

    color: white;
    font-size: 1.2vw;
    font-weight: 700;
    text-decoration: none;

    transition: all 0.3s;
}

.page-selector-special:hover {
    color: #1E90FF;

    border-bottom: #1E90FF 0.139vw solid;
}

.page-selector{
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;

    margin-left: 3vw;

    color: white;
    font-size: 1.2vw;
    font-weight: 700;
    text-decoration: none;

    transition: all 0.3s;
}

.page-selector:hover {
    color: #1E90FF;

    border-bottom: #1E90FF 0.139vw solid;
}

#highlighted {
    color: #1E90FF;

    border-bottom: #1E90FF 0.139vw solid;
}

.modal-wrapper {
    background-color: #1E90FF;
    height: 100vh;
    width: 100vw;

    opacity: 1;
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    padding-top: 4vw;
    padding-bottom: 2vw;
    padding-left: 4vw;
    padding-right: 2vw;
}

.header-wrapper img {
    height: 5vw;
    width: auto;
}

.modal-wrapper img {
    height: 5vw;
    width: auto;
    cursor: pointer;
}

.links-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 5vw;
    padding-bottom: 2vw;
    padding-left: 2vw;
    padding-right: 2vw;
}

.name-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    text-decoration: none;
}

.name-container h1 {
    color: white;
    font-size: 7vw;
    font-weight: 700;
    margin-top: 1.5vh;
    margin-bottom: 3vh;
}

.name-container h2 {
    color: white;
    font-size: 5vw;
    font-weight: 400;
    margin-top: 1.5vh;
    margin-bottom: 0;
}

a:link a:visited a:hover a:active{
  text-decoration: none;
  color: #02112E;
}


@media screen and (max-width: 650px) {
    .nav-bar{
        height: 7vh;
        width: 100vw;
        background-image: none;
        background-color: #02112E;
        border-bottom: 0.139vw solid #02112E;
        /* border-bottom-right-radius: 3.5vw;
        border-bottom-left-radius: 3.5vw; */
    }

    #not-auth {
        height: 10vw;

        border-bottom: none;

        background-color: transparent;
        background-image: url("../../images/navbar.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: none;
    }

    #not-auth .nav-bar-wrapper {
        justify-content: flex-end;
    }

    .nav-bar-wrapper{
        width: 95%; /* Increased to fit better in mobile */

        align-items: center;
        align-content: center;
    }

    .nav-bar .language-button {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 6vw;

        margin-right: 0;

        font-size: 0vw;

        visibility: collapse;
    }

    .nav-bar img {
        height: 5vw;
    }

    #not-auth .buttons-wrapper {
        padding-top: 3vw;
    }

    .buttons-wrapper {
        padding-top: 0;

        align-items: center;
        align-content: center;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
    }

    .page-selector-special {
        /* color: #02112E; */
        font-size: 2.5vw;
    }

    .page-selector {
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 0;
        padding-right: 0;

        margin-right: 0.4vw;
        margin-left: 0.4vw;

        background-color: #02112E;
        font-size: 2.8vw;
        font-weight: 400;
        color: white;

        border: 1px solid #02112E;
        border-radius: 1.2vw;

        flex: 1;
        white-space: nowrap;
        width: 19vw;
    }

    .page-selector:hover {
        border-bottom: #1E90FF 0.24vw solid;
    }

    #highlighted {
        border-bottom: #1E90FF 0.24vw solid;
    }

  .profile-img img {
    height: 12vw !important;
  }

  .events-button {
    font-size: 4vw;
    color: white;
    background-color: #02112E;
    font-weight: 600;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
  }


  .events-button-highlight {
    font-size: 4vw;
    color: #02112E;
    background-color: white;
    font-weight: 600;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
  }
}

@media screen and (min-width:650px) {
    /* change size of modal and of text that is rendered in it */

    .modal-wrapper {
        height: 100vh;
        width: 40vw;
    }

    .header-wrapper {
        padding-top: 4vw;
        padding-bottom: 2vw;
        padding-left: 4vw;
    }

    .modal-wrapper img {
        height: 3vw;
        cursor: pointer;
        margin-right: 10%;
    }

    .links-wrapper {
      padding: 5vw 2vw 2vw;
    }

    .name-container {
        width: 80%;
    }

    .name-container h1 {
        font-size: 3vw;
        font-weight: 700;
        margin-top: 1.5vh;
        margin-bottom: 3vh;
    }

    .name-container h2 {
        font-size: 2vw;
        font-weight: 400;
        margin-top: 1.5vh;
        margin-bottom: 0;
    }

  .events-button {
    font-size: 2vw;
    color: white;
    background-color: #02112E;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
  }

  .events-button:hover {
    color: #02112E;
    background-color: white;
  }

  .events-button-highlight {
    font-size: 2vw;
    color: #02112E;
    background-color: white;

    padding: 0.5rem 1.5rem;
    border-radius: 0.8vw;
    border: none;

    text-decoration: none;

    margin-right: 2vw;
  }

  .profile-img img {
    height: 5.5vw !important;
  }
}



