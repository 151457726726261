.email-code-background{
    width: 100%;
    height: 100vh;
    min-height: 300px;

    display: flex;

    background-color: #252F46;
}

.email-code-main {
    height: 90%;
    width: 92%;

    margin: auto; /* Used to center the object */

    display: flex;

    position: relative; /* Used since we are going to have a position: absolute on a child */

    background-color: white;
    border: 0.14vw white solid;
    border-radius: 1.4vw;
    box-shadow: 0 0 1.4vw 0.14vw rgba(0, 0, 0, 0.5);

}

.email-code-container {
    width: 70%;
    height: 100%;
    box-sizing: border-box; /* So it includes the padding */

    margin: auto; /* Used to center the object */
    
    padding-top: 17%;
    padding-bottom: 17%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;  
} 

.email-code-container .back{
    width: 1.74vw;
    height: 1.74vw;

    position: absolute; /* We want this fixed to main at a distance of the top of 1.74vw and off the left of 1.74vw */
    top: 1.4vw;
    left: 1.4vw;

    color: #02112E;
    font-size: 1.53vw;
}

.email-code-container h1 {
    font-size: 2.29vw;
}

#email-code-secondary-text{
    width: 70%;
    
    margin: auto; /* Used to center the object */

    font-size: 1.25vw;

}

#email-code-button-wrapper {
    margin-top: 1.74vw;
}

/* Coding the input dynamic. Followed this video https://www.youtube.com/watch?v=IxRJ8vplzAo&t=456s */

input {
    font-family: 'Montserrat' , sans-serif;
}

input:-webkit-autofill { /* This code was made to remove the yellow background when we auto fill data */
    box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}

.form {
    height: 3.47vw;
    width: 60%;

    margin-top: 0;
    margin-bottom: 0.7vw;
    margin-left: auto; /* Used to center the object */
    margin-right: auto; /* Used to center the object */

    position: relative;

    overflow: hidden;
}

.form input {
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    padding-top: 1.25vw;

    border: none;
    outline: none;

    font-size: 1.25vw;
    font-weight: 600;

}

.form label {
    height: 100%;
    width: 100%;

    position: absolute;
    bottom: 0;
    left: 0;
    
    border-bottom: 0.07vw solid black;
    pointer-events: none;

    font-size: 1.25vw;
    font-weight: 400;
    text-align: left;
}

.form label::after{
    height: 100%;
    width: 100%;

    position: absolute;
    bottom: -0.07vw;
    left: 0;

    border-bottom: 0.21vw solid #02112E;
    content: "";

    transform: translateX(-100%);
    transition: all 0.3s ease;
}

.content {
    position: absolute;
    bottom: 0.35vw;
    left: 0;

    transition: all 0.3s ease;
}

.form input:focus + .label .content,
.form input:valid + .label .content{
    margin-bottom: 0.3vw;

    transform: translateY(-150%);
    
    color: #02112E;
    font-size: 0.85vw;
    font-weight: 400;
}

.form input:focus + .label::after{
    transform: translateX(0%);
}

/* If we want the underline to stay after the text is filled

.form input:focus + .label::after, 
.form input:valid + .label::after{
    transform: translateX(0%);
}

*/

/* Coding the input dynamic OVER */

@media screen and (max-width: 650px) { 
    .email-code-background {
        height: stretch; /* This is used so that the view occupies all available space */
        min-height: 140vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */
    }

    .email-code-main {
        height: calc(195px + 54vh); /* Since percentage measures weren't working we used this calculation that is a 
                                    combination between a fixed measure ( px ) and a measure that varies with the 
                                    height of the viewport ( vh ). */
        min-height: 130vw; /* On the desktop the height wasn't big enough thus we needed to fix a min height that 
                           relates with the width of the viewport. Also important for smaller phones.  */
    }

    .email-code-container {
        width: 85%;
        height: 55%;

        padding-top: 0;
        padding-bottom: 0;

        margin-top: auto; /* Used to center the object */
        margin-bottom: auto; /* Used to center the object */


    } 

    .email-code-container .back{
        top: 6vw;
        left: 6vw;

        color:#02112E;
        font-size: 4vw; 

    }

    .email-code-container h1 {
        margin-top: 0;

        font-size: 6vw;
    }
    
    #email-code-secondary-text{
        width: 100%;

        font-size: 3.6vw;
    }

    #email-code-button-wrapper {
        margin-top: 12vw;
    }

    .form {
        height: 11.5vw;
        width: 69vw;
    }

    .form input {
        margin-left: -0.4vh;

        padding-top: 5.5vw;

        font-size: 3.5vw;

    }

    .form label {
        width: 66vw;
        
        margin-left: 1.5vw;

        border-bottom: 0.23vw solid black;
        
        font-size: 3.5vw;
    }

    .form label::after{
        border-bottom: 0.6vw solid #02112E;

        transform: translateX(-180%);
    }

    .form input:focus + .label .content,
    .form input:valid + .label .content{
        margin-bottom: 1vw;

        font-size: 2.2vw;
    }

}