.name {
    color: #02112E;
    width: 100%;
    text-align: left;
    margin-left: 12vw;
    margin-top: 3vw;
    font-size: 7vw;
    font-weight: 700;
}

.wrapper {
    min-height: 100vh;
    height: auto;
    width: auto;
    min-width: 100vw;

    background-image: none;
    background-color: white;
    border-top: 2vh solid white;
    border-top-right-radius: 3.5vw;
    border-top-left-radius: 3.5vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2vw;
}

.qrcode {
    margin-top: 6vw;
    height: 45vw;
    width: 45vw;
    justify-content: center;
    align-items: center;
    display: flex;

    background-color: white;

    background-image: url("../../images/qrcodebackground.png");
    background-size: cover;
    background-position: center;
    border-radius: 5vw;
    padding: 11vw;
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.257); /* Add a shadow */
}

.qrcode svg {
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
}

.tickets {
    min-width: 100vw;
    margin-top: 6vw;
    text-align: left;
    max-width: 100vw;
    margin-bottom: 10vw;
    background-color: white;
}

.tickets-title {
    font-size: 5vw;
    font-weight: 800;
    margin-left: 13vw;
}

.ticket-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 70vw;
    margin: 0 auto;
    background-color: white;
}

.used-button {
    height: 10vw;
    width: 30vw;
    border: none;
    box-shadow: none;
    border-radius: 1vw;

    color: #02112E;
    font-weight: 500;
    font-size: 2.3vw;
    background-color: white;
}

.used-button:hover {
    background-color: #02112E;
    color: white;
}

.highlighted-used-button {
    height: 10vw;
    width: 30vw;
    border: none;
    box-shadow: none;
    border-radius: 1vw;

    background-color: #02112E;
    color: white;
    font-weight: 500;
    font-size: 2.3vw;
}

.tickets-bought-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vw;
    margin-left: 13vw;

    overflow-x: scroll;
    scrollbar-width: none;

    background-color: white;
}

.ticket-bought-div {
    margin-right: 7vw;
    width: auto;
    height: auto;
}

.tickets-bought-wrapper::-webkit-scrollbar {
    display: none;
}

.tickets-bought-wrapper::-ms-scrollbar {
    display: none;
}

.no-tickets {
    display: flex;
    align-items: flex-start;
    height: 200px;
    background-color: white;
}

.no-tickets-text {
    margin-left: 13vw;
    font-size: 18px;
    color: #333;
    font-size: 2.3vw;
    font-weight: 400;
}

.qrcode-text {
    width: 50vw;
    text-align: left;
    font-size: 3vw;
}

@media screen and (min-width: 650px){
    /* adjust sizes, but change to percentage units*/

    .name {
        order: 1;
        margin-top: 3vw;
        margin-left: 13vw;
        font-size: 2.5vw;
    }

    .qrcode-wrapper {
        order: 3;
        margin-top: 3vw;
        margin-bottom: 3vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .qrcode {
        height: 15vw;
        width: 15vw;
        padding: 2vw;
        border-radius: 2vw;
        margin-top: 0;
    }

    .qrcode-text {
        width: 30vw;
        font-size: 1.5vw;
    }

    .tickets {
        order: 2;
        margin-top: 3vw;
        margin-bottom: 3vw;
        margin-left: 13vw;
        margin-right: 13vw;
        text-align: left;
    }

    .tickets-title {
        font-size: 2.5vw;
    }

    .ticket-buttons-wrapper {
        width: 30vw;
        margin: 0 auto;
        margin-left: 13vw;
    }

    .used-button {
        height: 5vw;
        width: 15vw;
        font-size: 1.5vw;
        margin-right: 5vw;
    }

    .highlighted-used-button {
        height: 5vw;
        width: 15vw;
        font-size: 1.5vw;
        margin-right: 5vw;
    }

    .tickets-bought-wrapper {
        order: 2;
        margin-top: 3vw;
        margin-bottom: 3vw;
        margin-left: 13vw;
        margin-right: 13vw;

        overflow-x: scroll;
    }

    .ticket-bought-div {
        margin-right: 3vw;
    }

    .no-tickets {
        height: 100px;
    }

    .no-tickets-text {
        font-size: 1.5vw;
    }

    .tickets-bought-wrapper::-webkit-scrollbar {
        display: none;
    }

    .tickets-bought-wrapper::-ms-scrollbar {
        display: none;
    }

    .tickets-bought-wrapper {
        margin-left: 13vw;
        margin-right: 20vw;
    }

    .tickets-bought-wrapper :nth-child(1) {
        margin-left: 0;
    }

    .ticket-bought-div {
        margin-right: 3vw;
        margin-left: 3vw;
    }

    .no-tickets {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .no-tickets-text {
        margin-left: 0;
        font-size: 1.5vw;
    }
}
