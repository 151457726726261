.members-container {
  width: 100%;
  
  flex: 1;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.custom-file-input {
  width: 9.8vw;

  display: flex;
  align-items: center;
  cursor:pointer;

  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  height: 2.4vw;
  width: 9.5vw;

  margin-top: 13%;

  display: inline-block;

  background-color: white;
  border: 0.11vw #02112E solid;
  border-radius: 0.5vw;
  
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  
  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 0.85vw;
  font-weight: 500;
  text-align: center;
  line-height: 2.4vw;
  text-decoration: none;
}
#pt::before {
  content: 'Seleciona o CSV'
}
#en::before {
  content: 'Select the CSV'
}
.custom-file-input:active::before  {
  box-shadow: 0 0 0.4vw 0.02vw rgba(2, 17, 46, 0.4);
}

.members-list {
  width: 82vw;
  height: 45vw;

  box-sizing: border-box;

  padding-top: 0.4vw;

  margin-left: auto;
  margin-right: auto;

  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.member-submit-button {
  position: absolute;
  right: 0;
  bottom: -3.5vw;

  height: 3.5vw;
  width: 14vw;

  font-size: 1.2vw;
}

.member-container {
  width: 78.5vw;

  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 1vw;
  padding-right: 1vw;

  margin-bottom: 0.7vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;
  border-radius: 1vw;
  box-shadow: 0 0 0.3vw 0.05vw rgba(2, 17, 46, 0.3);
} 

.member-info-wrapper {
  width: 58%;
  height: 4vw;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.member-phone-wrapper {
  width: 29%;
  height: 4vw;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.member-info-wrapper h3 {
  margin: 0;

  color: #0F6DF9;
  font-size: 1.1vw;
  font-weight: 600;
  text-align: left;
}

.member-info-wrapper h4 {
  margin: 0;

  color: #02112E;
  font-size: 0.9vw;
  font-weight: 400;
  text-align: left;
}

.member-phone-wrapper h3 {
  margin: 0;

  color: #0F6DF9;
  font-size: 1.1vw;
  font-weight: 600;
  text-align: right;
}

.members-csv-info-container {
  width: 100%;

  margin-top: 0.5vw;
  margin-bottom: 0.5vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.members-csv-info-text {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.members-csv-info-text p, .members-csv-info-text h4 {
  width: 100%;

  margin-top: 0vw;
  margin-bottom: 0.2vw;
  text-align: left;
}

.members-csv-info-text p {
  color: #02112E;
  font-size: 0.9vw;
  font-weight: 400;
}

.members-csv-info-text h4 {
  color: #02112E;
  font-size: 1vw;
  font-weight: 600;
}

.members-warning {
  margin: 0.4vw;

  color: #D42000;
  font-size: 1vw;
  font-weight: 600;
}

.disabled {
  cursor: default;
}

.disabled:active{
  box-shadow: none;
}
