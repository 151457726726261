.payment-button {
  height: 5vh;
  width: 90%;

  margin-top: 1.5vw;
  margin-left: 0;

  background-color: #f2f2f2;
  border: 0.14vw #f2f2f2 solid;
  border-radius: 0.69vw;
  box-sizing: border-box;
  cursor:pointer;

  color: #02112e;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.9vw;
  font-weight: 510;
  text-align: center;
  text-decoration: none;

  transition: all 0.3s;
}

.payment-button img {
  width:5.5%;
  margin-right: 3%;
}

.payment-button:hover {
  border: 0.14vw #0F6DF9 solid;
  border-radius: 0.69vw;
  box-sizing: border-box;
  background-color: #0F6DF9;
  color: white;
}

.payment-button:active {
  box-shadow: 0 0 0.95vw 0.02vw rgba(2, 17, 46, 0.7);
}

.payment-button:focus {
  outline: 0; /* In Google Chrome an undesired outline around buttons was popping */
}

#alternative-color-scheme {
  background-color: white;
  border: 0.139vw white solid;

  color: #02112E;
}

#alternative-color-scheme:hover {
  background-color: #02112E;

  color: white;
}

#line-only {
  background-color: transparent;
  border: 0.139vw white solid;

  color: white;
}

#line-only:hover {
  background-color: transparent;

  color: white;
}

#alternative-color-scheme:active {
  box-shadow: 0 0 0.95vw 0.02vw rgba(255, 255, 255, 0.7);
}

.payment-button-loading {
  height: 90%;
  width: 90%;

  margin-top: 1.5vw;
  margin-left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f2f2f2;
  border: 0.14vw #f2f2f2 solid;
  border-radius: 0.69vw;
  box-sizing: border-box;

  color: #02112E;

  transition: all 0.3s;
}

@media screen and (max-width: 650px) {
  .payment-button {
    width: 70vw;
    height: 9vw;

    margin-top: 4%;

    border-radius: 1.4vw;

    color: #02112e;
    font-family: 'Montserrat' , sans-serif;
    font-size: 2.8vh;
    font-weight: 510;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    transition: all 0.3s;
  }

  .payment-button img {
    width:9%;
    margin-right: 3%;
  }

  .payment-button-loading {
    width: 70vw;
    height: 9vw;

    border-radius: 1.4vw;
  }

  .payment-button:active {
    box-shadow: 0 0 2.2vw 0.2vw rgba(2, 17, 46, 0.7);
  }

  #alternative-color-scheme {
    background-color: #02112E; /* Had to change the alternative color scheme since the background is always white */
    border: 0.278vw #02112E solid; /* Had to change the alternative color scheme since the background is always white */

    color: white; /* Had to change the alternative color scheme since the background is always white */
  }

  #alternative-color-scheme:hover {
    background-color: white; /* Had to change the alternative color scheme since the background is always white */

    color: #02112E; /* Had to change the alternative color scheme since the background is always white */
  }

  #alternative:active {
    box-shadow: 0 0 2.2vw 0.2vw rgba(255, 255, 255, 0.7);
  }

  #different-size{
    height: 7.7vw;
    width: 22.2vw;

    border: 0.27vw #02112E solid;
    border-radius: 1.5vw;

    font-size: 2.8vw;
  }

  #line-only{
    height: 5vw;
    width: 21vw;

    background-color: transparent;
    border: 0.139vw white solid;
    border-radius: 1.5vw;

    color: white;

    font-size: 2vw;
  }

}
