.add-edit-consumable-body-main .form-wrapper {
  width: 70%;

  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
}

.form-wrapper #text-input-form {
  width: 100%;

  margin-left: 0;
}

.form-wrapper #select-input-div {
  width: 100%;

  margin: 0;
}

.wrapper-category {
  padding-top: 0.26vw;
}

.add-edit-consumable-price {
  width: 100%;

  margin-bottom: 1vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-edit-consumable-price-single {
  width: 40%;
}

.add-edit-consumable-body-main .optional-text {
  width: 100%;
  
  margin-top: 2vw;
  margin-bottom: 0.5vw;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.3vw;
  font-weight: 700;
}

.add-edit-consumable-body-main .large-size-text {
  width: 100%;

  margin-top: 0.3vw;
  margin-bottom: 0;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.2vw;
  font-weight: 600;
}

.add-edit-consumable-body-main .promo-text {
  width: 100%;

  margin-top: 1.5vw;
  margin-bottom: -0.2vw;
  margin-left: 0;

  color: #02112E;
  text-align: left;
  font-size: 1.2vw;
  font-weight: 600;
}

.add-edit-consumable-promo-date {
  width: 100%;

  margin-top: 0vw;
  margin-bottom: 0vw;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}

.add-edit-consumable-promo-date h2 {
  height: min-content;
  width: 15%;

  padding-top: 4%;

  color: #02112E;
  text-align: left;
  font-size: 1.2vw;
  font-weight: 400;
}

.add-edit-consumable-promo-date #time-wrapper {
  width: 25%;
}

.add-edit-consumable-promo-date #date-wrapper {
  width: 45%;

  padding-left: 0%;
  padding-right: 0%;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.add-edit-consumable-body-main h4 {
  width: 100%;
  height: min-content;

  margin: 0;
  margin-top: 1.5vw;

  color: #02112E;
  font-family: 'Montserrat' , sans-serif;
  font-size: 1.1vw;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}