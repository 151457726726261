.ticket-card-bought-wrapper {
    position: inherit;
    width: 65vw;
    height: 32vw;
    background-image: url("../../assets/ticketBoughtBackground.svg");
    opacity: 0.85;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    box-shadow: 0 0 1vw rgba(2, 17, 46, 0.2); /* Set the shadow color */;
    border-radius: 3vw;
}

.ticketInfoWrapper {
    width: 95%;
    height: 18vw;
    margin-left: 5%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.ticketName {
    color: #0F6DF9;
    font-family: Montserrat;
    font-size: 4.5vw;

    font-weight: 700;
    text-align: left;
}

.ticketDatesWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.ticketDates {
    color: #02112E;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 2.2vw;

    text-align: left;
    margin: 0;
}

.ticketHours {
    color: #02112E;
    font-family: Montserrat;
    font-weight: 200;
    font-size: 2.2vw;

    margin: 0;
    margin-left: 8vw;
}

.validationInfoWrapper {
    width: 100%;
    height: 12vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.validationInfoText {
    color: #02112E;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 3vw !important;
    font-size: medium;
    font-style: italic;
    text-align: center;
}

@media screen and (min-width:650px) {
    /* Put everything with half of the size */

    .ticket-card-bought-wrapper {
        width: 30vw;
        height: 15vw;
        background-image: url("../../assets/ticketBoughtBackground.svg");
        opacity: 0.85;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        box-shadow: 0 0 1vw rgba(2, 17, 46, 0.2); /* Set the shadow color */;
        border-radius: 1.5vw;
    }

    .ticketInfoWrapper {
        width: 95%;
        height: 9vw;
        margin-left: 5% !important;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ticketName {
        color: #0F6DF9;
        font-family: Montserrat;
        font-size: 2.5vw;

        font-weight: 700;
        text-align: left;
    }

    .ticketDatesWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .ticketDates {
        color: #02112E;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 1.2vw;

        text-align: left;
        margin: 0;
    }

    .ticketHours {
        color: #02112E;
        font-family: Montserrat;
        font-weight: 200;
        font-size: 1.2vw;

        margin: 0;
        margin-left: 4vw;
    }

    .validationInfoWrapper {
        width: 100%;
        height: 6vw;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .validationInfoText {
        color: #02112E;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 1.5vw !important;
        font-size: medium;
        font-style: italic;
        text-align: center;
    }
}
