.progress-bar-line {
    width: 4vw;
    height: 0.8vw;
    margin-right: 0.4vw;
    margin-left: 0;

    border-radius: 0.2vw;
}

.progress-bar-total {
    width: min-content;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}